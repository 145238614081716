import styled, { css } from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'


export const SwiperWrap = styled.div`
  height: 100vh;
  .swiper-wrapper {
    height: 100vh;
    .swiper-slide {
      margin-bottom: 0 !important;
    }
  }
  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    //.swiper-wrapper {
    //  height: calc(var(--vh, 1vh) * 100);
    //}
  }
  @media screen and (max-width: 1199px) and (min-width: 884px) {
    //.swiper-wrapper {
    //  height: calc(var(--vh, 1vh) * 100);
    //}
  }

  @media screen and (max-width: 883px) {
    //.swiper-wrapper {
    //  height: calc(var(--vh, 1vh) * 100);
    //}
  }
}
`

export const BannerWrap = styled.div`
  padding-top:100px;
  display: flex;
  justify-content: center;
  height: calc(100vh - 100px);
  
  .banner_wrap {
    //position: relative;
    //padding: 130px 0 0;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
    .video_wrap {
      display: flex;
      justify-content: center;
      
      .video {
        width: 1400px;
        clip-path: inset(1px 1px);
      }
      .video:focus { outline:none; }
      .lottie_service {
        position: absolute;
      }
    }
  }
  .arrow_area {
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 70px;
    .arrow_text {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: ${STYLE_GUIDE.COLOR.black3};
    }
    .lottie_arrow {
      width: 26px;
      padding: 8px 0 0;
    }
  }
  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .banner_wrap {
      //padding: 34px 0 0;
      .video_wrap {
        .video {
          width: 920px;
        }
      }
      .arrow_area {
        padding: 41px 0 0;
        bottom: 50px;
      }
    }
  }
  @media screen and (max-width: 1199px) and (min-width: 884px) {
    height: calc(100vh - 70px);
    padding-top:70px;
    .banner_wrap {
      //padding: 84px 0 0;
      text-align: center;
      .video_wrap {
        .video {
          width: 560px;
        }
        .lottie_service {
          width: 560px;
        }
      }
    }
    .arrow_area {
      bottom: 50px;
      .arrow_text {
        font-size: 16px;
      }
      .lottie_arrow {
        padding: 4px 0 0;
        width: 30px;
      }
    }
  }
  @media screen and (max-width: 883px) {
    height: calc(100vh - 70px);
    padding-top:70px;
    .banner_wrap {
      top: 40%;
      transform: translate(0, -50%);
      //padding: 70px 0 0;
      .video_wrap {
        .video {
          width: 290px;
        }
        .lottie_service {
          width: 290px;
        }
      }
    }
    .arrow_area {
      bottom: 120px;
      .arrow_text {
        font-size: 16px;
      }
      .lottie_arrow {
        padding: 4px 0 0;
        width: 30px;
      }
    }
  }
`

export const SloganWrap = styled.div`
  text-align: left;
  position: relative;
  height: 100vh;
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video_cation_area {
    position: absolute;
    display: flex;
    top: 0;
    justify-content: space-between;
    padding: 340px 0 0;
    
    .caption {
      opacity: 0;
    }
    .video_cation1 {
      width: 736px;
      font-weight: 700;
      font-size: 46px;
      line-height: 62px;
    }
    .video_cation2,.video_cation3 {
      font-weight: 400;
      font-size: 28px;
      line-height: 40px;
    }
    .video_cation2 {
      width: 385px;
    }
    .video_cation3 {
      padding: 50px 0 0;
      width: 485px;
    }
  }
  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .video_cation_area {
      padding: 200px 0 0;
      .video_cation1,.video_cation2,.video_cation3 {
        width: 480px;
      }
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    .video_cation_area {
      padding: 210px 0 0;
      .video_cation1,.video_cation2,.video_cation3{
        width: 320px;
      }
      .video_cation1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
      }
      .video_cation2,.video_cation3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }
      .video_cation3 {
        padding: 20px 0 0; 
      }
    }
  }

  @media screen and (max-width: 883px) {
    .video_cation_area {
      display: block;
      padding: 150px 0 0;
      .video_cation1,.video_cation2,.video_cation3{
        width: 320px;
      }
      .video_cation1 {
        padding: 0 0 54px;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
      }
      .video_cation2,.video_cation3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }
      .video_cation3 {
        padding: 20px 0 0;
      }
    }
  }
`

export const SectionWrap1 = styled.div`
  padding: 152px 0 0 0;

  .version_item {
    display: flex;
    justify-content: space-between;
  }

  .version_item:not(:last-child) {
    padding: 0 0 160px 0;
  }

  .version_area {
    position: sticky;
    align-self: flex-start;
    top: 150px;
    padding: 0 134px 0 0;
  }

  .version {
    width: 139px;
    font-weight: 800;
    font-size: 46px;
    line-height: 52px;
    color: ${STYLE_GUIDE.COLOR.black1};
  }

  .version_img {
    > img {
      border-radius: 40px;
    }
  }
  .version_title {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: ${STYLE_GUIDE.COLOR.black1};
    padding: 40px 0 20px 0;
  }
  .version_detail {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }

  .icon_area {
    display: flex;
    align-items: center;
    padding: 30px 0 0 0;
    .icon_wrap {
      display: flex;
      align-items: center;
    }
  }

  .icon_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: ${STYLE_GUIDE.COLOR.black3};
    padding: 0 40px 0 10px;
    
    &.sub {
      color: ${STYLE_GUIDE.COLOR.black4};
    }
  }

  .unique {
    padding: 30px 0 0 0;
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    color: ${STYLE_GUIDE.COLOR.black1};
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    //padding: 200px 0 0 0;

    .version_content {
      width: 742px;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    padding: 100px 0 0 0;

    .version_item {
      display: block;
    }
    .version_item:not(:last-child) {
      padding: 0 0 100px 0;
    }

    .version_area {
      position: inherit;
    }

    .version {
      font-size: 30px;
      line-height: 38px;
    }

    .version_content {
      padding: 20px 0 0 0;
    }
    .version_img {
      > img {
        border-radius: 30px;
      }
    }
    .version_title {
      font-size: 22px;
      line-height: 34px;
      padding: 30px 0 10px;
    }

    .version_detail {
      font-size: 20px;
      line-height: 32px;
    }

    .icon_area {
      padding: 40px 0 0 0;
    }

    .icon_title {
      padding: 0px 24px 0px 10px;
      font-size: 18px;
      line-height: 30px;
    }
  }

  @media screen and (max-width: 883px) {
    padding: 100px 0 0 0;
    .version_item {
      display: block;
    }

    .version_item:not(:last-child) {
      padding: 0 0 100px;
    }

    .version {
      font-size: 30px;
      line-height: 38px;
    }
    .version_area {
      position: inherit;
    }

    .version_img {
      padding: 20px 0 0 0;
    }

    .version_title {
      font-size: 22px;
      line-height: 34px;
      padding: 30px 0px 10px;
    }

    .version_detail {
      font-size: 20px;
      line-height: 32px;
    }
    .unique {
      font-size: 18px;
      line-height: 30px;
    }

    .icon_area {
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      // row-gap: 20px;
      display: inline-block;

      .icon_wrap:first-child {
        float: left;
        padding: 0 20px 20px 0;
      }
      .icon_wrap:last-child {
        float: left;
      }

      .icon_title {
        font-size: 18px;
        line-height: 30px;
        padding: 0px 0px 0px 10px;
      }
    }
  }
`
export const SectionWrap2 = styled.div`
  &.patent_cert_section {
    padding: 250px 0 0 0;
  }

  .section_title {
    padding: 0 0 30px 0;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
    color: ${STYLE_GUIDE.COLOR.black1};
  }
  .item_wrap {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;

    .sub_title {
      // padding: 0 40px 0 0;
      width: 233px;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: ${STYLE_GUIDE.COLOR.black1};
    }

    .patent_list_wrap {
      .patent_list {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: ${STYLE_GUIDE.COLOR.black1};
        width: 1327px;
        padding: 0 0 40px 0;

        > li {
          display: flex;
          justify-content: space-between;
        }

        > li:not(:last-child) {
          padding: 0 0 28px;
        }

        .patent_title:hover {
          cursor: pointer;
        }

        .patent_reg_dt {
          font-weight: 400;
          font-size: 24px;
          line-height: 26px;
          text-align: right;
          color: #999999;
          float: right;
        }
      }
      .pagination {
        padding: 40px 0 0;
      }
    }

    .main_img,
    .sub_img {
      img {
        border-radius: 40px;
      }
    }

    .sub_img {
      display: flex;
      justify-content: space-between;
      padding: 40px 0 0 0;

      img {
        cursor: pointer;
        border: 1px solid #dbdbdb;
      }
    }
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    &.patent_cert_section {
      padding: 200px 0 0 0;
    }

    .item_wrap {
      .patent_list_wrap {
        width: 742px;

        .patent_list {
          width: 100%;
          padding: 0 0 40px 0;

          .patent_title {
            width: 555px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      .main_img,
      .sub_img {
        img {
          border-radius: 30px;
        }
      }

      .sub_img {
        padding: 30px 0 0 0;

        img {
          width: 360px;
        }
      }
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    &.patent_cert_section {
      padding: 140px 0 0 0;
    }

    .section_title {
      font-size: 36px;
      line-height: 48px;
      padding: 0px 0px 16px;
    }

    .item_wrap {
      display: block;
      padding: 30px 0 70px;

      .sub_title {
        font-size: 28px;
        line-height: 40px;
        padding: 0 0 20px 0;
      }

      .patent_list_wrap {
        .patent_list {
          width: 100%;
          font-size: 16px;
          line-height: 30px;
          padding: 0 0 30px 0;

          > li:not(:last-child) {
            padding: 0 0 14px 0;
          }

          .patent_title {
            width: 555px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .patent_reg_dt {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .top.patent_list_wrap {
        display: block;
      }

      .patent_list_wrap {
        display: flex;
        justify-content: space-between;
      }

      .main_img,
      .sub_img {
        img {
          border-radius: 30px;
        }
      }

      .sub_img {
        flex-direction: column;
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 883px) {
    &.patent_cert_section {
      padding: 140px 0 0 0;
    }

    .section_title {
      font-size: 36px;
      line-height: 48px;
      padding: 0 0 16px;
    }

    .item_wrap {
      padding: 30px 0 70px 0;
      display: block;

      .sub_title {
        font-size: 28px;
        line-height: 40px;
      }

      .patent_list_wrap {
        padding: 20px 0 0;

        .patent_list {
          width: 100%;
          padding: 0 0 30px;

          > li:not(:last-child) {
            padding: 0px 0px 14px;
          }

          > li {
            align-items: center;
          }

          .patent_title {
            font-size: 16px;
            line-height: 30px;
            width: 207px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .patent_reg_dt {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .main_img,
      .sub_img {
        img {
          border-radius: 30px;
        }
      }
      .sub_img {
        display: block;
        padding: 30px 0 0;

        figure:first-child {
          padding: 0 0 20px;
        }
      }
    }
  }
`
export const SectionWrap3 = styled.div`
  padding: 100px 0 0 0;
  color: ${STYLE_GUIDE.COLOR.black1};

  .section_title {
    padding: 0 0 30px 0;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
  }

  .history_item {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;

    li {
      display: flex;
      padding: 0 0 30px 0;
    }

    .history_content.w_history_content {
      width: 1327px;
    }

    .history_area {
      position: sticky;
      align-self: flex-start;
      top: 150px;
    }

    .year {
      font-family: 'Roboto';
      font-weight: 800;
      font-size: 46px;
      line-height: 52px;
    }

    .month {
      padding: 0 13px 0 0;
      font-weight: 500;
      font-size: 28px;
      line-height: 38px;
    }

    .history_detail {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
    }

    img {
      border-radius: 40px;
    }
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .history_item {
      padding: 50px 0 30px 0;
      .history_content {
        width: 742px !important;

        .small_img {
          width: 382px;
        }
      }
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    padding: 70px 0 0 0;

    .section_title {
      font-size: 36px;
      line-height: 48px;
      padding: 0 0 16px;
    }

    .history_item {
      display: block;
      padding: 30px 0 10px 0;

      .history_area {
        padding: 0 0 20px 0;
        position: inherit;
      }

      .history_content.w_history_content {
        width: 100%;
      }
      .year {
        font-size: 30px;
        line-height: 38px;
      }
      .month {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      }

      .history_detail {
        font-size: 18px;
        line-height: 30px;
      }

      img {
        border-radius: 30px;
      }
    }
  }

  @media screen and (max-width: 883px) {
    padding: 70px 0 0 0;

    .section_title {
      font-size: 36px;
      line-height: 48px;
      padding: 0 0 16px;
    }

    .history_item {
      padding: 30px 0 0 0;
      display: block;

      .history_area {
        position: relative;
        top: 0;
      }

      .year {
        font-size: 30px;
        line-height: 38px;
        padding: 0 0 20px;
      }

      .month {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        padding: 0 10px 0 0;
      }

      .history_detail {
        font-size: 18px;
        line-height: 30px;
      }

      .history_content {
        width: auto !important;

        .middle {
          padding: 0 0 14px;
        }
      }

      img {
        border-radius: 30px;
      }
    }
  }
`
export const SectionWrap4 = styled.div`
  padding: 170px 0 250px;
  color: ${STYLE_GUIDE.COLOR.black1};

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .section_title {
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
  }

  .section_info {
    padding: 20px 0 130px 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    padding: 140px 0 150px;
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    padding: 100px 0 145px;

    .section_title {
      font-size: 36px;
      line-height: 48px;
    }

    .section_info {
      font-size: 16px;
      line-height: 30px;
      padding: 10px 0px 50px;
      text-align: center;
    }
  }

  @media screen and (max-width: 883px) {
    padding: 110px 0 140px;

    .section_title {
      font-size: 36px;
      line-height: 48px;
    }
    .section_info {
      padding: 10px 0 50px;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
    }
  }
`
export const SectionWrap5 = styled.div`
  background: ${STYLE_GUIDE.COLOR.gray5};
  color: ${STYLE_GUIDE.COLOR.black1};

  .section_title {
    padding: 100px 0 30px 0;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
  }

  .item_wrap {
    padding: 50px 0 20px 0;
    display: flex;
    justify-content: space-between;

    .right_wrap {
      width: 1326px;
    }

    .sub_title {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: ${STYLE_GUIDE.COLOR.black1};
    }

    .item_desc {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      padding: 30px 0 0 0;
    }

    .color.item_desc {
      padding: 30px 0 20px 0;
    }

    .center_img_box {
      height: 360px;
      background: #fff;
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.03);
      border-radius: 40px;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  .color.item_wrap {
    padding: 50px 0 150px 0;
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .item_wrap {
      padding: 50px 0 0 0;
      .right_wrap {
        width: 736px;
      }

      .sub_title {
        padding: 0 17px 0 0;
        width: 226px;
      }

      .center_img_box {
        height: 220px;
        width: 736px;

        img {
          width: 320px;
        }
        .item_desc {
          width: 736px;
        }
      }
    }

    .color.item_wrap {
      padding: 30px 0 120px;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    .section_title {
      padding: 60px 0px 16px;
      font-size: 46px;
      line-height: 52px;
    }

    .item_wrap {
      padding: 30px 0 0 0;
      display: block;

      .right_wrap {
        width: 100%;
        padding: 20px 0 0 0;
      }

      .center_img_box {
        width: 764px;
        height: 250px;

        img {
          width: 320px;
        }
      }

      .item_desc {
        width: 764px;
        padding: 20px 0 0 0;
        font-size: 18px;
        line-height: 30px;
      }

      .ci_btn {
        font-size: 14px;
        line-height: 24px;
        padding: 6px 16px 8px;
      }
    }

    .color.item_wrap {
      padding: 30px 0 60px 0;
    }
  }

  @media screen and (max-width: 883px) {
    .section_title {
      font-weight: 800;
      font-size: 46px;
      line-height: 52px;
      padding: 60px 0 16px;
    }

    .item_wrap {
      display: block;
      padding: 30px 0 0 0;

      .sub_title {
        font-weight: 800;
        font-size: 30px;
        line-height: 38px;
        padding: 0 0 20px 0;
      }

      .right_wrap {
        width: auto;
      }

      .center_img_box {
        height: 250px;

        img {
          width: 260px;
        }
      }

      .item_desc {
        font-size: 18px;
        line-height: 30px;
        padding: 20px 0 0 0;
      }

      .ci_btc {
        font-size: 14px;
        line-height: 24px;
        padding: 6px 16px 8px;
      }
    }

    .color.item_wrap {
      padding: 30px 0 60px 0;
    }
  }
`

export const SectionWrap6 = styled.div`
  color: ${STYLE_GUIDE.COLOR.black1};
  .contact_section {
    display: flex;
    padding: 150px 0 0;
    justify-content: space-between;

    h3 {
      padding: 0 0 30px 0;
      font-weight: 700;
      font-size: 46px;
      line-height: 58px;
    }

    .sub_info {
      font-weight: 700;
      font-size: 28px;
      line-height: 40px;
    }
    .tel_wrap {
      padding: 140px 0 0 0;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;

      .tel_number {
        display: block;
      }
    }

    .contact_wrap {
      padding: 242px 0 0 0;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      span {
        display: block;
      }
    }
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .contact_section {
      padding: 100px 0 0;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    .contact_section {
      padding: 100px 0 0;

      h3 {
        font-size: 32px;
        line-height: 44px;
        padding: 0 0 20px;
      }

      .sub_info {
        font-size: 18px;
        line-height: 30px;
      }

      .tel_wrap {
        font-size: 16px;
        line-height: 30px;
        padding: 109px 0 0;
      }

      .contact_wrap {
        font-size: 16px;
        line-height: 30px;
        //padding: 10px 0 0;
        padding: 179px 0 0;
      }
    }
  }

  @media screen and (max-width: 883px) {
    .contact_section {
      display: block;
      padding: 100px 0 0;

      h3 {
        font-size: 32px;
        line-height: 44px;
        padding: 0 0 20px;
      }

      .sub_info {
        font-size: 18px;
        line-height: 30px;
      }

      .tel_wrap {
        font-size: 16px;
        line-height: 30px;
        padding: 40px 0 0;
      }

      .contact_wrap {
        font-size: 16px;
        line-height: 30px;
        padding: 10px 0 30px;
      }
    }
  }
`
