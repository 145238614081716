import { useEffect } from 'react'

import styled from 'styled-components'

const { kakao } = window

const MapWrap = styled.div`
  width: 1053px;
  border-radius: 10px;

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    width: 485px;
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    width: 433px;
  }

  @media screen and (max-width: 883px) {
    width: 320px;
    height: 420px;
  }
`

const MapComponent = () => {
  useEffect(() => {
    const container = document.getElementById('map')
    const options = {
      center: new kakao.maps.LatLng(37.5238640247561, 126.924142368161), //지도의 중심좌표.
      level: 3,
    }
    const map = new kakao.maps.Map(container, options)

    // 마커를 표시할 위치입니다
    const position = new kakao.maps.LatLng(37.5238640247561, 126.924142368161)
    // 마커를 생성합니다
    const marker = new kakao.maps.Marker({
      position: position,
    })

    // 마커를 지도에 표시합니다.
    marker.setMap(map)
    // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
    var mapTypeControl = new kakao.maps.MapTypeControl()

    // 지도에 컨트롤을 추가해야 지도위에 표시됩니다
    // kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT)

    // 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
    var zoomControl = new kakao.maps.ZoomControl()
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT)

    // 마커에 커서가 오버됐을 때 마커 위에 표시할 인포윈도우를 생성합니다
    var iwContent = '<div style="padding:5px;">서울특별시 영등포구 국제금융로2길 32</div>' // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다

    // 인포윈도우를 생성합니다
    var infowindow = new kakao.maps.InfoWindow({
      content: iwContent,
    })

    // 마커에 마우스오버 이벤트를 등록합니다
    kakao.maps.event.addListener(marker, 'mouseover', function () {
      // 마커에 마우스오버 이벤트가 발생하면 인포윈도우를 마커위에 표시합니다
      infowindow.open(map, marker)
    })

    // 마커에 마우스아웃 이벤트를 등록합니다
    kakao.maps.event.addListener(marker, 'mouseout', function () {
      // 마커에 마우스아웃 이벤트가 발생하면 인포윈도우를 제거합니다
      infowindow.close()
    })
  }, [])
  return <MapWrap id="map"></MapWrap>
}

export default MapComponent
