import { PaginationContainer, PreviousBtn, NextBtn, IndexBtn } from 'styles/common/paging'
import paginationEnableBtn from 'assets/icon/pagination_enable.png'
import paginationDisableBtn from 'assets/icon/pagination_disable.png'
import paginationBar from 'assets/icon/pagianation_bar.png'
import { useState, useEffect } from 'react'
import { getNewsTotalNum } from 'service/fetchs'

const Pagination = (props) => {
  const { totalNum, divisionNum } = props
  const [page, setPage] = useState([])
  const [lastPage, setLastPage] = useState()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentlastPage, setCurrentLastPage] = useState()

  const division = (array) => {
    const length = array.length
    const divide = Math.floor(length / 5) + (Math.floor(length % 5) > 0 ? 1 : 0)
    const newArray = []
    for (let i = 0; i < divide; i++) {
      // 배열 0부터 n개씩 잘라 새 배열에 넣기
      newArray.push(array.splice(0, 5))
    }
    return newArray
  }

  const NextBtnClick = () => {
    if (currentPage !== lastPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  const PreviustBtnClick = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  useEffect(() => {
    const page = Math.ceil(Number(totalNum) / divisionNum) // 현재 총 페이지 수 = 총 개수/6
    let tempData = []
    for (var i = 1; i <= page; i++) {
      tempData.push(i)
    }
    setLastPage(tempData.slice(-1)[0])
    setPage(division(tempData))
  }, [totalNum])

  useEffect(() => {
    page.forEach((d, idx) => {
      if (d.includes(currentPage)) {
        setCurrentIndex(idx)
        setCurrentLastPage(d.slice(-1)[0])
      }
    })
    props.setPageNum(currentPage)
  }, [currentPage])

  return (
    <PaginationContainer>
      <PreviousBtn onClick={() => PreviustBtnClick()} currentPage={currentPage}>
        {currentPage > 1 && <img src={paginationEnableBtn} alt="" />}
        {currentPage === 1 && <img src={paginationDisableBtn} alt="" />}
      </PreviousBtn>
      <NextBtn onClick={() => NextBtnClick()} currentPage={currentPage} lastPage={lastPage}>
        {currentPage !== lastPage && <img src={paginationEnableBtn} alt="" />}
        {currentPage === lastPage && <img src={paginationDisableBtn} alt="" />}
      </NextBtn>
      {page.map(
        (p, idx) =>
          idx === currentIndex &&
          p.map((d) => (
            <IndexBtn
              key={d}
              active={currentPage === d}
              onClick={() => {
                setCurrentPage(d)
              }}
            >
              {d} <img src={paginationBar} alt="left" />
            </IndexBtn>
          )),
      )}
    </PaginationContainer>
  )
}

export default Pagination
