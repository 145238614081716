import { Route, Routes } from 'react-router-dom'
import Home from 'views/Home'
import Information from 'views/Information'
import Service from 'views/Service'
import Recruit from 'views/Recruit'
import News from 'views/News'
import ScrollToTop from 'component/common/ScrollToTop'

const DataUniversRoute = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="home" element={<Home />}></Route>
        <Route path="information" element={<Information />}></Route>
        <Route path="service" element={<Service />}></Route>
        <Route path="news" element={<News />}></Route>
        <Route path="recruit" element={<Recruit />}></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
    </ScrollToTop>
  )
}

export default DataUniversRoute
