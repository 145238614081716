import styled from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'

export const MenuWrap = styled.div`
  margin-left: auto;
  .main-menu {
    text-align: center;
    line-height: 100px;

    .info_tab {
      width: 129px;
      .depth2 {
        width: 129px;
      }
    }
    .service_tab {
      width: 83px;
      .depth2 {
        width: 83px;
      }
    }
    .news_tab {
      width: 37px;
      .depth2 {
        width: 37px;
      }
    }
    .recurit_tab {
      width: 114px;
      .depth2 {
        width: 114px;
      }
    }
  }
  .main-menu > li {
    display: inline-block;
    padding-right: 100px;

    @media screen and (max-width: 1919px) and (min-width: 1200px) {
      padding-right: 60px;
    }

    > a {
      color: ${(props) =>
        props.menuFlag || props.pathname !== '/'
          ? `${STYLE_GUIDE.COLOR.black1}`
          : `${STYLE_GUIDE.COLOR.white}`};
      &:hover {
        color: ${STYLE_GUIDE.COLOR.main1};
      }
      &.active {
        color: ${STYLE_GUIDE.COLOR.main1};
      }
    }

    :last-child {
      padding-right: 0px;
    }

    .depth2 {
      position: absolute;
      z-index: 5;
      font-weight: 400;
      font-size: 18px;
      color: ${STYLE_GUIDE.COLOR.black1};
      line-height: 30px;
      > li {
        padding-top: 20px;
      }
    }
  }

  .mobile_menu {
    position: fixed;
    background: ${STYLE_GUIDE.COLOR.black1};
    color: #fff;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    top: 70px;
    width: 100%;
    > ul > li {
      font-size: 28px;
      line-height: 40px;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    .main-menu > li {
      padding-right: 60px;
    }
  }
`
