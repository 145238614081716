const STYLE_GUIDE = {
    COLOR: {
      main1: '#C01F25',
      main2: '#DB485B',
      main3: '#F0A8BB',
      main4: '#95181D',
      main5: '#511315',
      mainLight1: '#F9DCE4',
      mainLight2: '#FEF6F8',
      black1: '#252525',
      black2: '#3D3D3D',
      black3: '#636363',
      black4: '#999999',
      gray1: '#B1B1B1',
      gray2: '#DBDBDB',
      gray3: '#EDEDED',
      gray4: '#F4F4F4',
      gray5: '#FAFAFA',
      white: '#FFFFFF',
    },
    Button: {
      antiScam: 'linear-gradient(180deg, #3DC289 0%, #0A9F5F 100%)',
      mFinder: 'linear-gradient(180deg, #8DD66D 0%, #42B06B 100%)',
      autocall: 'linear-gradient(180deg, #13A0CA 0%, #1BB1DE 0.01%, #0189B1 100%)',
      familyCare: 'linear-gradient(180deg, #F96D60 0%, #FF4545 100%)',
    },
    SERVICE: {
        antiScam: '#BAF5D5',
        subAutocall: '#B1EEF1',
        subFamily: '#FFD9D9',
        subEventu: '#F0DCFF',
    },
  }
  export default STYLE_GUIDE
  