import axios, { AxiosResponse } from 'axios'

/**
 * 서버와 통신하는 api 함수.
 */
export const axiosDefaultData = async (url) =>
  await axios.get(hostServer() + url, {
    withCredentials: true,
  })

/**
 * 요청 상세주소를 입력하면 현재 실행중인 클라이언트의 도메인에 따라 전체 요청 URL을 반환하는 함수
 * @returns {string} host를 포함한 전체 URL을 반환
 */
export const hostServer = () => {
  const host = window.location.hostname
  if (host.includes('localhost')) return 'https://dev-durs.datau.co.kr' //'http://5.0.30.24:1007'
  if (host.includes('dev')) return 'https://dev-durs.datau.co.kr'
  return 'https://dev-durs.datau.co.kr'
}
