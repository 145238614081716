import styled from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'

export const DefaultTabButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 22px 12px;
  border-radius: 50px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  background: ${STYLE_GUIDE.COLOR.gray3};
  color: ${STYLE_GUIDE.COLOR.black1};
  cursor: pointer;

  &:hover {
    background: ${STYLE_GUIDE.COLOR.gray1};
    color: ${STYLE_GUIDE.COLOR.white};
  }

  &.on {
    background: ${STYLE_GUIDE.COLOR.black1};
    color: ${STYLE_GUIDE.COLOR.white};
  }
`

export const BlackTabButton = styled(DefaultTabButton)`
  background: ${STYLE_GUIDE.COLOR.black1};
  color: ${STYLE_GUIDE.COLOR.white};
`

export const GrayTabButton = styled(DefaultTabButton)`
  background: ${STYLE_GUIDE.COLOR.gray1};
  color: ${STYLE_GUIDE.COLOR.white};
`

export const GrayTabButton2 = styled(DefaultTabButton)`
  background: ${STYLE_GUIDE.COLOR.black1};
  color: ${STYLE_GUIDE.COLOR.gray3};
`
