import { useRef, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'
import JSZip from 'jszip'
import Lottie from 'lottie-react'
import arrow from 'assets/json/du_arrow.json'
import serviceFront from 'assets/json/duservice-front.json'
import mobileServiceFront from 'assets/json/mobile-duservice-front.json'
import {
  SwiperWrap,
  BannerWrap,
  SloganWrap,
  SectionWrap1,
  SectionWrap2,
  SectionWrap3,
  SectionWrap4,
  SectionWrap5,
  SectionWrap6,
} from 'styles/views/Information'
import { MainButton3 } from 'styles/common/button'
import Pagination from 'component/common/Pagination'
import PatentModal from 'component/common/PatentModal'
import RollingPartner from 'component/RollingPartner'
import MapComponent from 'component/MapComponent'
import du1 from 'assets/images/info/img_company_web01_1327.png'
import du2 from 'assets/images/info/img_company_web02_1327.png'
import du3 from 'assets/images/info/img_company_web03_1327.png'
import mobileDu1 from 'assets/images/info/img_company_web01_764.png'
import mobileDu2 from 'assets/images/info/img_company_web02_764.png'
import mobileDu3 from 'assets/images/info/img_company_web03_764.png'
import miniDu1 from 'assets/images/info/img_company_web01.png'
import miniDu2 from 'assets/images/info/img_company_web02.png'
import miniDu3 from 'assets/images/info/img_company_web03.png'
import antiScamIcon from 'assets/icon/service/ico_antiscam_50.png'
import autocallIcon from 'assets/icon/service/ico_autocall_50.png'
import eventuIcon from 'assets/icon/service/ico_eventu_50.png'
import familycareIcon from 'assets/icon/service/ico_familycare_50.png'
import mfinderIcon from 'assets/icon/service/ico_mfinder_50.png'
import gearIcon from 'assets/icon/service/ico_gear_50.png'
import cert from 'assets/images/info/img_2022-t3_1327.png'
import koika from 'assets/images/info/img_koika_643.png'
import innobiz from 'assets/images/info/img_innobiz_643.png'
import mobileCert from 'assets/images/info/img_2022-t3_367.png'
import mobileKoika from 'assets/images/info/img_koika_643-.png'
import mobileInnobiz from 'assets/images/info/img_koika_643-1.png'
import symbol from 'assets/images/info/img_ci-logo_450.png'
import ci from 'assets/images/info/img_ci-color_450.png'
import history01 from 'assets/images/info/img_history-01_1327.png'
import history02 from 'assets/images/info/img_history-02_1327.png'
import history03 from 'assets/images/info/img_history-03_680.png'
import history04 from 'assets/images/info/img_history-04_1327.png'
import history05 from 'assets/images/info/img_history-05_680.png'
import history06 from 'assets/images/info/img_history-06_680.png'
import history07 from 'assets/images/info/img_history-07_1327.png'
import history08 from 'assets/images/info/img_history-08_680.png'
import history09 from 'assets/images/info/img_history-09_1327.png'
import mhistory01 from 'assets/images/info/img_history-01_764.png'
import mhistory02 from 'assets/images/info/img_history-02_764.png'
import mhistory03 from 'assets/images/info/img_history-03_764.png'
import mhistory04 from 'assets/images/info/img_history-04_764.png'
import mhistory05 from 'assets/images/info/img_history-05_764.png'
import mhistory06 from 'assets/images/info/img_history-06_764.png'
import mhistory07 from 'assets/images/info/img_history-07_764.png'
import mhistory08 from 'assets/images/info/img_history-08_764.png'
import mhistory09 from 'assets/images/info/img_history-09_764.png'
import Logo from 'assets/ci/로고.png'
import Logo_Kor from 'assets/ci/혼합형로고타입.png'
import Symbol from 'assets/ci/Symbol.png'

import { getPatentList, getPatentTotalNum } from 'service/fetchs'
import duVision from 'assets/video/DU_vison_bg.mp4'
import TopButton from '../component/common/TopButton'
import VideoComponent from '../component/VideoComponent'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import { Mousewheel } from 'swiper'

const Information = () => {
  const textRef = useRef(null)
  const { state } = useLocation()
  const [position, setPosition] = useState(window.pageYOffset)
  const [visible, setVisible] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isInnobiz, setIsInnobiz] = useState(false)
  const [patentsImg, setPatentsImg] = useState()

  const [patentList, setPatentList] = useState([])
  const [totalNum, setTotalNum] = useState()
  const [pageNum, setPageNum] = useState(1)
  const [videoSource, setVideoSource] = useState()
  const [touchHeight, setTouchHeight] = useState(0)
  const [swiperIndex, setSwiperIndex] = useState(0)

  const sectionRef = useRef([])
  const tabRef = useRef([])
  const [sloganFlag, setSloganFlag] = useState(true)
  const swiperRef = useRef()

  const isPc = useMediaQuery({
    query: '(min-width:1200px)',
  })
  const isLaptop = useMediaQuery({
    query: '(max-width:1919px) and (min-width:1200px)',
  })
  const isTablet = useMediaQuery({
    query: '(min-width:884px) and (max-width:1199px)',
  })
  const isPhone = useMediaQuery({
    query: '(max-width:883px)',
  })
  const isMobile = useMediaQuery({
    query: '(max-width:1199px)',
  })

  useEffect(() => {
    setTimeout(() => {
      if (state?.state) {
        const section = document.querySelectorAll('.section')
        let sections = {}
        Array.prototype.forEach.call(section, function (e) {
          sections[e.id] = e.offsetTop - document.querySelector('.header')?.offsetHeight
        })
        if (state.state === 1) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          swiperRef.current.swiper.enable()
          swiperRef.current.swiper.slideTo(0)
        } else {
          window.scrollTo({ top: sections['section' + state.state], behavior: 'smooth' })
        }
        state.state = null
        window.history.replaceState({}, document.title)
      }
    }, 400)
  }, [state?.state])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const enableSwiper = () => {
    document.getElementsByClassName('swiper-vertical')[0].style.touchAction = 'pan-x'
    swiperRef.current.swiper?.enable()
  }

  const disableSwiper = () => {
    document.getElementsByClassName('swiper-vertical')[0].style.touchAction = 'pan-y'
    swiperRef.current.swiper?.disable()
  }

  const elementRef = useRef(null)
  const scrollHandler = (e) => {
    let st = window.scrollY || document.documentElement.scrollTop
    if (e.type === 'wheel') {
      if (st === 0 && !swiperRef.current.swiper?.enabled && e.deltaY < 0) {
        enableSwiper()
      }
      return
    }
    if (st === 0 && !swiperRef.current.swiper.enabled) {
      enableSwiper()
    } else {
      /*console.warn(`scrollHandler: st: ${st}, enabled: ${swiperRef.current.swiper.enabled}` )
      disableSwiper()*/
    }
  }

  useEffect(() => {
    // function ani() {
    //   const fadeinEls = document.querySelectorAll('.caption')
    //   fadeinEls.forEach(function (textanimation, index) {
    //     gsap.fromTo(textanimation,
    //       {opacity: 0, y: 50},
    //       {opacity: 1, y: 0, duration: 1, delay: (index + 1) * 0.6}
    //     )
    //   })
    // }
    //
    // ScrollTrigger.create({
    //   trigger: '#section1',
    //   start: 'top 30%',
    //   scrub: false,
    //   onEnter: ({progress, direction, isActive}) => (isActive ? ani() : null),
    //   onLeaveBack: self => self.disable(),
    //   repeat: 0,
    // })

    window.addEventListener('scroll', scrollHandler)
    window.addEventListener('wheel', scrollHandler)
    window.addEventListener('touchstart', touchStartHandler)
    window.addEventListener('touchend', touchEndHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
      window.removeEventListener('wheel', scrollHandler)
      window.removeEventListener('touchstart', touchStartHandler)
      window.removeEventListener('touchend', touchEndHandler)
    }
  }, [])

  useEffect(() => {
    getPatents()
  }, [pageNum])

  const getPatents = async () => {
    const patentData = await getPatentList(pageNum, 10)

    setPatentList(patentData.data)

    const num = await getPatentTotalNum()
    setTotalNum(num.data[0].totalData)
  }

  const ciDownload = () => {
    const image = [
      { img: Logo, name: 'Logo' },
      { img: Symbol, name: 'Symbol' },
      { img: Logo_Kor, name: 'Logo_Kor' },
    ]

    Promise.all(image.map((url) => fetch(url.img).then((response) => response.blob()))).then(
      async (blobs) => {
        const zip = new JSZip()

        blobs.forEach((d, i) => {
          zip.file(image[i].name + '.png', d)
        })

        zip.generateAsync({ type: 'blob' }).then(function (blob) {
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'DataUniverse CI.zip')
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        })
      },
    )
  }

  const serviceStyle = {
    width: isLaptop ? '920px' : '1400px',
  }

  function ani() {
    const fadeinEls = document.querySelectorAll('.caption')
    fadeinEls.forEach(function (textanimation, index) {
      gsap.fromTo(
        textanimation,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1, delay: index === 0 ? 0 : (index + 1) * 0.2 },
      )
    })
  }

  let startTouchY = 0
  let endTouchY = 0

  const touchStartHandler = (e) => {
    startTouchY = e.changedTouches[0].clientY
  }

  const touchEndHandler = (e) => {
    endTouchY = e.changedTouches[0].clientY
    setTouchHeight(Math.trunc(startTouchY) - Math.trunc(endTouchY))

    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  useEffect(() => {
    if (touchHeight > 0 && swiperRef.current.swiper.enabled && swiperRef.current.swiper.isEnd) {
      disableSwiper()

      // window.scrollBy({
      //   top: touchHeight,
      //   left: 0,
      //   behavior: "smooth"
      // })
    } else {
      enableSwiper()
    }
  }, [touchHeight])

  const duLottieRef = useRef()
  useEffect(() => {
    switch (swiperIndex) {
      case 0:
        setTimeout(() => {
          duLottieRef.current.goToAndPlay(0, true)
        }, 1000)
        break
      case 1:
        if (sloganFlag) {
          ani()
          setSloganFlag(false)
        }
        break
      default:
    }
  }, [swiperIndex])

  return (
    <>
      <SwiperWrap>
        <Swiper
          ref={swiperRef}
          direction={'vertical'}
          slidesPerView={1}
          mousewheel={{
            forceToAxis: true,
            sensitivity: 1,
            // releaseOnEdges: true,
          }}
          speed={1000}
          touchReleaseOnEdges={true}
          allowTouchMove={true}
          modules={[Mousewheel]}
          onSlideChange={(swiper) => {
            setTimeout(function () {
              setSwiperIndex(swiper.activeIndex)
            }, 1000)
          }}
          onReachEnd={(swiper) => {
            setTimeout(function () {
              disableSwiper()
            }, 1000)
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <BannerWrap>
              <div className="banner_wrap container">
                <div className="video_wrap">
                  <VideoComponent />
                  {isMobile ? (
                    <Lottie
                      lottieRef={duLottieRef}
                      loop={false}
                      autoplay={false}
                      animationData={mobileServiceFront}
                      className="lottie_service"
                    />
                  ) : (
                    <Lottie
                      lottieRef={duLottieRef}
                      loop={false}
                      autoplay={false}
                      animationData={serviceFront}
                      className="lottie_service"
                      style={serviceStyle}
                    />
                  )}
                </div>
              </div>
              <div className="arrow_area">
                {/*<p className="arrow_text">자세히 보기</p>*/}
                <Lottie animationData={arrow} className="lottie_arrow" />
              </div>
            </BannerWrap>
          </SwiperSlide>
          <SwiperSlide>
            <SloganWrap ref={elementRef} className="section" id="section1">
              <video className="video" autoPlay={true} loop muted playsInline="playsinline">
                <source src={duVision} type="video/mp4" />
                <strong>Your browser does not support the video tag.</strong>
              </video>
              <div className="container">
                <div className="container video_cation_area">
                  <div className="caption video_cation1">
                    금융사기, 휴대폰분실, 개인정보유출, 가족돌봄 및 소통 부족 등 사회문제를 해결하기
                    위해
                  </div>
                  <div className="sub_caption">
                    <div className="caption video_cation2">
                      정보통신기술(ICT) 분야의
                      <br /> 기술력과 서비스 경험을 바탕으로
                    </div>
                    <div className="caption video_cation3">
                      웹 3.0시대에 맞는 지능적이고 발전된 Global ICT 서비스를 만들어갑니다.
                    </div>
                  </div>
                </div>
              </div>
            </SloganWrap>
          </SwiperSlide>
        </Swiper>
      </SwiperWrap>
      <SectionWrap1 className="container version_section">
        <div className="version_item">
          <div className="version_area">
            <h2 className="version">DU 1.0</h2>
          </div>
          <div className="version_content">
            <figure className="version_img">
              {isPhone && <img src={miniDu1} alt="" />}
              {isTablet && <img src={mobileDu1} alt="" />}
              {isPc && <img src={du1} alt="" />}
            </figure>
            <h3 className="version_title">기술로 사회문제를 해결합니다.</h3>
            <p className="version_detail">
              데이터유니버스는 ICT 기술을 활용해 금융사기, 개인정보 유출, 자산 보호 등의 개인에게
              발생할 수 있는 문제를 해결하고자 스마트피싱보호(Anti-Scam), 휴대폰분실보호(MFinder),
              오토콜(Autocall)을 출시하였습니다. 새롭게 발생하는 위협으로부터 개인을 보호하기 위해
              노력하고 있습니다.
            </p>
            <div className="icon_area">
              <div className="icon_wrap">
                <img src={antiScamIcon} alt="" />
                <span className="icon_title">Anti-Scam</span>
              </div>
              <div className="icon_wrap">
                <img src={mfinderIcon} alt="" />
                <span className="icon_title">MFinder</span>
              </div>
              <div className="icon_wrap">
                <img src={autocallIcon} alt="" />
                <span className="icon_title">AutoCall</span>
              </div>
            </div>
          </div>
        </div>
        <div className="version_item">
          <div className="version_area">
            <h2 className="version">DU 2.0</h2>
          </div>
          <div className="version_content">
            <figure className="version_img">
              {isPhone && <img src={miniDu2} alt="" />}
              {isTablet && <img src={mobileDu2} alt="" />}
              {isPc && <img src={du2} alt="" />}
            </figure>
            <h3 className="version_title">연결을 통한 상생과 소통에 함께합니다.</h3>
            <p className="version_detail">
              데이터유니버스는 사람을 연결하고 함께 성장할 수 있는 플랫폼을 제공하기 위해
              휴대폰가족보호, 이벤트U를 출시하였습니다. 기술이 주는 편리함으로 사람들에게 행복과
              혜택을 줄 수 있는 서비스를 지속적으로 만들어 가겠습니다.
            </p>
            <div className="icon_area">
              <div className="icon_wrap">
                <img src={familycareIcon} alt="" />
                <span className="icon_title">FamilyCare</span>
              </div>
              <div className="icon_wrap">
                <img src={eventuIcon} alt="" />
                <span className="icon_title">EventU</span>
              </div>
              <div className="icon_wrap">
                <img src={gearIcon} alt="" />
                <span className="icon_title sub">MFinder Global 개발 중</span>
              </div>
            </div>
          </div>
        </div>
        <div className="version_item">
          <div className="version_area">
            <h2 className="version">DU 3.0</h2>
          </div>
          <div className="version_content">
            <figure className="version_img">
              {isPhone && <img src={miniDu3} alt="" />}
              {isTablet && <img src={mobileDu3} alt="" />}
              {isPc && <img src={du3} alt="" />}
            </figure>
            <h3 className="version_title">안전한 맞춤·지능형 서비스를 제공합니다.</h3>
            <p className="version_detail">
              데이터유니버스는 앞으로도 마이데이터 분석, AI를 통한 에듀테크, 인슈어테크 등 다양한
              분야에서 사람과 함께할 수 있는 맞춤형 서비스를 제공할 수 있도록 나아가겠습니다.
            </p>
            <p className="unique">DATAUNIVERSE, Make a better Tomorrow</p>
          </div>
        </div>
      </SectionWrap1>

      <SectionWrap2
        ref={(el) => (sectionRef.current[1] = el)}
        id="section2"
        className="container patent_cert_section section"
      >
        <PatentModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          innobizFlag={isInnobiz}
          patentsImg={patentsImg}
        />
        <h2 className="section_title">특허&인증</h2>
        <hr className="section_bar" />
        <div className="item_wrap">
          <h3 className="sub_title">특허 리스트</h3>
          <div className="top patent_list_wrap">
            <ul className="patent_list">
              {patentList.map((item) => (
                <li key={item.idx}>
                  <span
                    className="patent_title"
                    onClick={() => {
                      setPatentsImg(item.imagePath)
                      openModal()
                    }}
                  >
                    [{item.patentNum}] {item.patentName}
                  </span>
                  <span className="patent_reg_dt">{item.patentDate}</span>
                </li>
              ))}
            </ul>
            {patentList.length > 10 && (
              <Pagination
                className="pagination"
                totalNum={totalNum}
                setPageNum={setPageNum}
                divisionNum={10}
              />
            )}
          </div>
        </div>
        <div className="item_wrap">
          <h3 className="sub_title">인증 내역</h3>
          {isMobile ? (
            <div className="patent_list_wrap">
              <figure className="main_img">
                <img src={mobileCert} alt="" />
              </figure>
              <div className="sub_img">
                <button
                  onClick={() => {
                    setIsInnobiz(false)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={mobileKoika} alt="" />
                  </figure>
                </button>
                <button
                  onClick={() => {
                    setIsInnobiz(true)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={mobileInnobiz} alt="" />
                  </figure>
                </button>
              </div>
            </div>
          ) : (
            <div className="patent_list_wrap">
              <figure className="main_img">
                <img src={cert} alt="" />
              </figure>
              <div className="sub_img">
                <button
                  onClick={() => {
                    setIsInnobiz(false)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={koika} alt="" />
                  </figure>
                </button>

                <button
                  onClick={() => {
                    setIsInnobiz(true)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={innobiz} alt="" />
                  </figure>
                </button>
              </div>
            </div>
          )}
        </div>
      </SectionWrap2>

      <SectionWrap3
        ref={(el) => (sectionRef.current[2] = el)}
        id="section3"
        className="container section"
      >
        <h2 className="section_title">히스토리</h2>
        <hr />
        <div className="history_item">
          <div className="history_area">
            <h3 className="year">2022</h3>
          </div>
          <div className="history_content">
            <ul>
              <li className="middle">
                <span className="month">12</span>
                <p className="history_detail">
                  벤처창업유공포상 창업활성화 창업기업부문 중소벤처기업부 장관 표창 수상
                </p>
              </li>
              <li>
                {isMobile ? (
                  <img src={mhistory09} alt="" className="default_img" />
                ) : (
                  <img src={history09} alt="" className="default_img" />
                )}
              </li>
              <li>
                <span className="month">10</span>
                <p className="history_detail">
                  2022 4차 산업혁명 우수기업 과학기술정보통신부 장관상 수상
                </p>
              </li>
              <li className="middle">
                <span className="month">07</span>
                <p className="history_detail">
                  2022 K-사이버보안 우수기업 ‘개인정보보호위원회 위원장상’ 수상
                </p>
              </li>
              <li>
                {isMobile ? (
                  <img src={mhistory08} alt="" />
                ) : (
                  <img src={history08} alt="" className="small_img" />
                )}
              </li>
              <li>
                <span className="month">05</span>
                <p className="history_detail">
                  2022 비대면스타트업 육성사업 창업기업 선정, 2022 대한민국 모바일 대상 ‘스타트업’
                  부문 최우수상 수상
                </p>
              </li>
              <li>
                <span className="month">02</span>
                <p className="history_detail">
                  SK planet OK캐쉬백, 시럽 서비스 업무 제휴 계약 체결
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="history_item">
          <div className="history_area">
            <h3 className="year">2021</h3>
          </div>
          <div className="history_content">
            <ul>
              <li className="middle">
                <span className="month">11</span>
                <p className="history_detail">
                  『휴대폰분실보호』 2021 제 16회 디지털이노베이션 대상 수상
                </p>
              </li>
              <li>
                {isMobile ? (
                  <img src={mhistory07} alt="" />
                ) : (
                  <img src={history07} alt="" className="default_img" />
                )}
              </li>
              <li>
                <span className="month">10</span>
                <p className="history_detail">
                  데시앙 아파트 『오토콜』 주차안심번호판 제휴 계약 체결, 배포
                </p>
              </li>
              <li className="middle">
                <span className="month">09</span>
                <p className="history_detail">
                  2021 제4차 산업혁명 우수기업 ‘행정안전부 장관상’ 수상
                </p>
              </li>
              <li>
                {isMobile ? (
                  <img src={mhistory06} alt="" />
                ) : (
                  <img src={history06} alt="" className="small_img" />
                )}
              </li>
              <li>
                <span className="month">05</span>
                <p className="history_detail">
                  2021 대한민국 모바일 대상 ‘스타트업 보안‘ 부문 최우수상 수상
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="history_item">
          <div className="history_area">
            <h3 className="year">2020</h3>
          </div>
          <div className="history_content w_history_content">
            <ul>
              <li className="middle">
                <span className="month">09</span>
                <p className="history_detail">T-map 모빌리티 서비스 업무 제휴계약 체결</p>
              </li>
              <li>
                {isMobile ? (
                  <img src={mhistory05} alt="" />
                ) : (
                  <img src={history05} alt="" className="small_img" />
                )}
              </li>
              <li>
                <span className="month">06</span>
                <p className="history_detail">
                  2020 초기창업패키지사업 창업기업 선정, 2020 K-Global 엑셀러레이팅 프로그램 선정
                </p>
              </li>
              <li>
                <span className="month">05</span>
                <p className="history_detail">기술연구소설립, TCB 기술등급 T3 등급 획득</p>
              </li>
              <li>
                <span className="month">03</span>
                <p className="history_detail">EST security 서비스 업무 제휴계약 체결</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="history_item">
          <div className="history_area">
            <h3 className="year">2019</h3>
          </div>
          <div className="history_content">
            <ul>
              <li className="middle">
                <span className="month">10</span>
                <p className="history_detail">
                  서울핀테크랩 선정 및 입주
                  <br />
                  2019 글로벌금융대상 ‘한국핀테크산업협회장상 보안/인증 부문’ 수상
                </p>
              </li>
              <li>
                {isMobile ? (
                  <img src={mhistory04} alt="" />
                ) : (
                  <img src={history04} alt="" className="default_img" />
                )}
              </li>
              <li>
                <span className="month">07</span>
                <p className="history_detail">NMP 서비스 업무 제휴 계약 체결</p>
              </li>
              <li className="middle">
                <span className="month">05</span>
                <p className="history_detail">
                  (주)한화 드림플러스 액셀러레이팅 핀테크분야 5기 선정
                  <br />
                  고려대학교 빅데이터융합센터와 금융사기예방 공동연구 협약 체결
                </p>
              </li>
              <li>
                {isMobile ? (
                  <img src={mhistory03} alt="" />
                ) : (
                  <img src={history03} alt="" className="small_img" />
                )}
              </li>
              <li>
                <span className="month">04</span>
                <p className="history_detail">
                  안랩 서비스 업무 제휴계약 체결
                  <br />
                  NH디지털Challenge+1기 선정 및 입주
                </p>
              </li>
              <li className="middle">
                <span className="month">03</span>
                <p className="history_detail">
                  우리은행 포괄적 업무 협약 체결, 위비뱅크 서비스 오픈
                </p>
              </li>
              <li>
                {isMobile ? (
                  <img src={mhistory02} alt="" />
                ) : (
                  <img src={history02} alt="" className="default_img" />
                )}
              </li>
              <li>
                <span className="month">02</span>
                <p className="history_detail">LG U+ 서비스 업무 제휴 계약 체결</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="history_item">
          <div className="history_area">
            <h3 className="year">2018</h3>
          </div>
          <div className="history_content">
            <ul>
              <li>
                <span className="month">12</span>
                <p className="history_detail">KT 서비스 업무 제휴 계약 체결</p>
              </li>
              <li>
                <span className="month">11</span>
                <p className="history_detail">SK telecom 서비스 업무 제휴 계약 체결</p>
              </li>
              <li>
                <span className="month">10</span>
                <p className="history_detail">
                  NH농협은행『스마트피싱보호』 서비스 업무 제휴 계약 체결
                </p>
              </li>
              <li>
                <span className="month">06</span>
                <p className="history_detail">주식회사 데이터유니버스 설립</p>
              </li>
              <li>
                <img src={history01} alt="" className="default_img" />
              </li>
            </ul>
          </div>
        </div>
      </SectionWrap3>

      <SectionWrap4 ref={(el) => (sectionRef.current[3] = el)} id="section4" className="section">
        <div className="top container">
          <h2 className="section_title">파트너</h2>
          <span className="section_info">
            데이터유니버스와 함께하는{isMobile ? <br /> : ' '}든든한 파트너입니다.
          </span>
        </div>
        <RollingPartner />
      </SectionWrap4>

      <SectionWrap5 ref={(el) => (sectionRef.current[4] = el)} id="section5" className="section">
        <div className="container">
          <h2 className="section_title">CI</h2>
          <hr />
          <div className="item_wrap">
            <h3 className="sub_title">Symbol</h3>
            <div className="right_wrap">
              <div className="center_img_box">
                <img src={symbol} alt="" />
              </div>
              <p className="ci item_desc">
                데이터유니버스의 심볼은 데이터 분석 과정을 이미지로 형상화 한 것입니다. 수 많은
                ‘데이터’를 기반으로 더 나은 ‘유니버스’를 만들고자 하는 우리의 목표를 담았습니다.
              </p>
            </div>
          </div>
          <div className="color item_wrap">
            <h3 className="sub_title">Color</h3>
            <div className="right_wrap">
              <div className="center_img_box">
                <img src={ci} alt="" />
              </div>
              <p className="color item_desc">
                기본으로 DU Red 및 지정된 검정색과 회색을 사용하며, 밝은 배경에서 사용할 것을
                권장합니다. <br />
                브랜드 고유 이미지를 나타내기 위해 색상, 형태 변형을 금합니다.
              </p>
              <MainButton3 className="ci_btn" onClick={ciDownload}>
                데이터유니버스 CI 다운로드
              </MainButton3>
            </div>
          </div>
        </div>
      </SectionWrap5>
      <SectionWrap6 ref={(el) => (sectionRef.current[5] = el)} className="section" id="section6">
        <div className="container contact_section">
          <div className="">
            <h3 className="">
              서울특별시 영등포구
              <br />
              국제금융로 2길 32 <br />
              7층
            </h3>
            <span className="sub_info">여의도역 3번출구 도보 2분</span>
            {/*<div className="tel_wrap">
              <a className="tel_number" href="tel:02-6013-0625">
                Tel : 02-6013-0625
              </a>
              <span className="fax_number">Fax : 02-6105-6396</span>
            </div>*/}
            <div className="contact_wrap">
              <span>고객문의 : contact@datau.co.kr</span>
              <span>제휴문의 : biz@datau.co.kr</span>
            </div>
          </div>
          <MapComponent />
        </div>
      </SectionWrap6>
      <TopButton scroll={swiperRef} />
    </>
  )
}
export default Information
