import styled from 'styled-components'
import { useState, useEffect } from 'react'
import arrowIcon from 'assets/icon/Arrow 1 (Stroke).png'
import testImg from 'assets/images/main/business-hand-with-mobile-phone1.png'

import { getNewsList } from 'service/fetchs'

export const NewsItemWrap = styled.div`
  margin: 0 0 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: 30px;

  .news_item {
    display: inline-block;
    position: relative;
    overflow: hidden;

    width: 507px;
    height: 522px;
    background: #ffffff;
    // border: 1px solid #ededed;
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.12);
    border-radius: 40px;

    .content {
      height: 222px;
      padding: 0 40px;

      .news_title {
        padding: 40px 0 0 0;

        .title_wrap {
          max-height: 72px;

          .news {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            display: -webkit-box;
            margin: 0;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:hover {
          text-decoration: underline;

          .icon {
            padding: 12px 0 0 8px;
          }
        }
      }

      .icon {
        padding: 20px 0 0;
        transition: all 0.1s ease;
      }

      .detail_info {
        display: flex;
        position: absolute;
        bottom: 320px;
        align-items: center;
      }

      .category {
        border: 2px solid #dbdbdb;
        border-radius: 40px;
        padding: 2px 10px 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #999999;
      }

      .date {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #b1b1b1;
        padding: 0 0 0 10px;
      }
    }

    .thumbnail {
      height: 300px;
      background: url(${testImg}) no-repeat 50% 50% / cover;
    }
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;

    .news_item {
      width: 485px;
      height: 522px;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 20px;

    .news_item {
      width: 372px;
      height: 426px;

      .content {
        height: 236px;
        padding: 0 30px;

        .news_title {
          padding: 30px 0 0;
          .title_wrap {
            max-height: 96px;

            .news {
              font-weight: 700;
              font-size: 20px;
              line-height: 32px;
              -webkit-line-clamp: 3;
            }
          }
        }

        .icon {
          width: 14px;
        }

        .detail_info {
          bottom: 210px;
        }

        .category {
          font-size: 14px;
          line-height: 24px;
        }

        .date {
          font-size: 16px;
          line-height: 30px;
        }
      }

      .thumbnail {
        height: 190px;
        background: url(${testImg}) no-repeat 50% 50% / cover;
      }
    }
  }

  @media screen and (max-width: 883px) {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;

    .news_item {
      width: 320px;
      height: 426px;

      .content {
        height: 236px;
        padding: 0 30px;

        .news_title {
          padding: 30px 0 0;
          .title_wrap {
            max-height: 96px;

            .news {
              font-size: 20px;
              line-height: 32px;
              -webkit-line-clamp: 3;
            }
          }
        }
        .icon {
          width: 14px;
        }
        .detail_info {
          bottom: 210px;
        }

        .category {
          font-size: 14px;
          line-height: 24px;
        }

        .date {
          font-size: 16px;
          line-height: 30px;
        }
      }

      .thumbnail {
        height: 190px;
        background: url(${testImg}) no-repeat 50% 50% / cover;
      }
    }
  }
`

const NewsComponent = () => {
  const [newsList, setNewsList] = useState([])

  const getNews = async () => {
    const newsData = await getNewsList(1, 6)
    setNewsList(newsData.data)
  }

  useEffect(() => {
    getNews()
  }, [])

  // const newsList = [
  //   {
  //     idx: 1,
  //     category: '언론보도',
  //     title: '데이터유니버스, 지하철 보이스피싱 예방 캠페인 진행',
  //     thumbnailPath: '',
  //     linkPath: '',
  //     newsDate: '23-02-28',
  //   },
  //   {
  //     idx: 2,
  //     category: '새소식',
  //     title:
  //       '휴대폰 가족보호 추천해요 휴대폰 가족보호 추천해요휴대폰 가족보호 추천해요휴대폰 가족보호 추천해요휴대폰 가족보호 추천해요휴대폰 가족보호 추천해요!',
  //     thumbnailPath: '',
  //     linkPath: '',
  //     newsDate: '21-11-11',
  //   },
  //   {
  //     idx: 3,
  //     category: '새소식',
  //     title: '휴대폰 가족보호 추천해요',
  //     thumbnailPath: '',
  //     linkPath: '',
  //     newsDate: '21-11-11',
  //   },
  //   {
  //     idx: 4,
  //     category: '새소식',
  //     title: '휴대폰 가족보호 추천해요',
  //     thumbnailPath: '',
  //     linkPath: '',
  //     newsDate: '21-11-11',
  //   },
  //   {
  //     idx: 5,
  //     category: '새소식',
  //     title: '휴대폰 가족보호 추천해요',
  //     thumbnailPath: '',
  //     linkPath: '',
  //     newsDate: '21-11-11',
  //   },
  //   {
  //     idx: 6,
  //     category: '새소식',
  //     title: '휴대폰 가족보호 추천해요',
  //     thumbnailPath: '',
  //     linkPath: '',
  //     newsDate: '21-11-11',
  //   },
  // ]

  return (
    <NewsItemWrap className="new_container">
      {newsList.map((item) => (
        <div className="news_item" key={item.idx}>
          <div className="content">
            <h3 className="news_title">
              <a href={item.linkPath}>
                <div className="title_wrap">
                  <p className="news">{item.title}</p>
                </div>
              </a>
              <div className="icon">
                <img src={arrowIcon} alt="" />
              </div>
            </h3>
            <div className="detail_info">
              <div className="category">{item.category}</div>
              <div className="date">{item.newsDate}</div>
            </div>
          </div>
          <div className="thumbnail" thumbnail={item.thumbnailPath}>
            {/* <img src={item.thumbnailPath} alt="" /> */}
          </div>
        </div>
      ))}
    </NewsItemWrap>
  )
}

export default NewsComponent
