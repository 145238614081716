import NewsComponent from 'component/NewsComponent'
import Pagination from 'component/common/Pagination'
import styled, { css } from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'
import arrowIcon from 'assets/icon/Arrow 1 (Stroke).png'
import testImg from 'assets/images/main/business-hand-with-mobile-phone1.png'

import { useState, useEffect } from 'react'
import { getNewsList, getNewsTotalNum } from 'service/fetchs'
import TopButton from "../component/common/TopButton";

export const NewsWrap = styled.div`
  padding: 220px 0 0;
  .title {
    padding: 0 0 80px 0;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
    color: ${STYLE_GUIDE.COLOR.black1};
  }
  
  .news_paging {
    padding: 40px 0 0;
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    padding: 130px 0 0;
    .title {
      padding: 0 0 88px 0;
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: ${STYLE_GUIDE.COLOR.black1};
    }
  }

  @media screen and (max-width: 883px) {
    padding: 130px 0 0;
    .title {
      padding: 0 0 40px 0;
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: ${STYLE_GUIDE.COLOR.black1};
    }

    .news_paging {
      padding: 30px 0 0;
    }
  }
`
export const NewsItemWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: 30px;

  .news_item {
    display: inline-block;
    position: relative;
    overflow: hidden;

    width: 507px;
    height: 522px;
    background: #ffffff;
    // border: 1px solid #ededed;
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.12);
    border-radius: 40px;

    .content {
      height: 222px;
      padding: 0 40px;

      .news_title {
        padding: 40px 0 0 0;

        .title_wrap {
          max-height: 72px;

          .news {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            display: -webkit-box;
            margin: 0;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &:hover {
          text-decoration: underline;

          .icon {
            padding: 12px 0 0 8px;
          }
        }
      }

      .icon {
        padding: 20px 0 0;
        transition: all 0.1s ease;
      }

      .detail_info {
        display: flex;
        position: absolute;
        bottom: 320px;
        align-items: center;
      }

      .category {
        border: 2px solid #dbdbdb;
        border-radius: 40px;
        padding: 2px 10px 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #999999;
      }

      .date {
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #b1b1b1;
        padding: 0 0 0 10px;
      }
    }

    .thumbnail {
      height: 300px;
      ${(props) =>
        props.thumbnail &&
        css`
          background: url(${props.thumbnail}) no-repeat 50% 50% / cover;
        `}
    }
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;

    .news_item {
      width: 485px;
      height: 522px;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 20px;

    .news_item {
      width: 372px;
      height: 426px;

      .content {
        height: 236px;
        padding: 0 30px;

        .news_title {
          padding: 30px 0 0;
          .title_wrap {
            max-height: 96px;

            .news {
              font-weight: 700;
              font-size: 20px;
              line-height: 32px;
              -webkit-line-clamp: 3;
            }
          }
        }

        .icon {
          width: 14px;
        }

        .detail_info {
          bottom: 210px;
        }

        .category {
          font-size: 14px;
          line-height: 24px;
        }

        .date {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 883px) {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;

    .news_item {
      width: 320px;
      height: 426px;

      .content {
        height: 236px;
        padding: 0 30px;

        .news_title {
          padding: 30px 0 0;
          .title_wrap {
            max-height: 96px;

            .news {
              font-size: 20px;
              line-height: 32px;
              -webkit-line-clamp: 3;
            }
          }
        }
        .icon {
          width: 14px;
        }
        .detail_info {
          bottom: 210px;
        }

        .category {
          font-size: 14px;
          line-height: 24px;
        }

        .date {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }
`

export const ThumbnailWrap = styled.div`
  height: 300px;
  ${(props) =>
    props.thumbnail &&
    css`
      background: url(${props.thumbnail}) no-repeat 50% 50% / cover;
    `}

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    height: 190px;

    ${(props) =>
      props.thumbnail &&
      css`
        background: url(${props.thumbnail}) no-repeat 50% 50% / cover;
      `}
  }

  @media screen and (max-width: 883px) {
    height: 190px;
    ${(props) =>
      props.thumbnail &&
      css`
        background: url(${props.thumbnail}) no-repeat 50% 50% / cover;
      `}
  }
`

const News = () => {
  const [newsList, setNewsList] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [totalNum, setTotalNum] = useState()

  const getNews = async () => {
    const newsData = await getNewsList(pageNum, 6)
    setNewsList(newsData.data)

    const num = await getNewsTotalNum()
    setTotalNum(num.data[0].totalData)
  }

  // useEffect(() => {
  //   getNews()
  // }, [])

  useEffect(() => {
    console.log('pageNum : ', pageNum)
    getNews()
  }, [pageNum])

  return (<>
    <NewsWrap className="container">
      <h2 className="title">
        데이터유니버스의
        <br />
        모든 소식을 확인해 보세요.
      </h2>

      <NewsItemWrap className="new_container">
        {newsList.map((item) => (
          <div className="news_item" key={item.idx}>
            <div className="content">
              <h3 className="news_title">
                <a href={item.linkPath} target="_blank" rel="noreferrer">
                  <div className="title_wrap">
                    <p className="news">{item.title}</p>
                  </div>
                </a>
                <div className="icon">
                  <img src={arrowIcon} alt="" />
                </div>
              </h3>
              <div className="detail_info">
                <div className="category">{item.category}</div>
                <div className="date">{item.newsDate}</div>
              </div>
            </div>
            <ThumbnailWrap className="thumbnail" thumbnail={item.thumbnailPath}>
              {/* <img src={item.thumbnailPath} alt="" /> */}
            </ThumbnailWrap>
          </div>
        ))}
      </NewsItemWrap>
      {newsList.length > 6 && (
          <div className="news_paging">
        <Pagination totalNum={totalNum} setPageNum={setPageNum} divisionNum={6}></Pagination>
          </div>
      )}
    </NewsWrap>
    <TopButton/>
      </>
  )
}

export default News
