import styled, { keyframes } from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'

const setOpacticy = keyframes` 
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const FooterWrap = styled.footer`
  width: 100%;
  height: 234px;
  background-color: #fff;
  border-top: 1px solid ${STYLE_GUIDE.COLOR.gray3};

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    height: 314px;
  }
  @media screen and (max-width: 1199px) and (min-width: 884px) {
    height: 351px;
  }
  @media screen and (max-width: 883px) {
    height: 397px;
  }
`

export const FooterContent = styled.div`
  display: flex;
  padding-top: 50px;
  justify-content: space-between;

  .logo_area {
    img {
      width: 210px;
    }
  }

  .info_area {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .address_area {
    margin: 0 176px 0 130px;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    line-height: 30px;
    color: ${STYLE_GUIDE.COLOR.black3};

    .sub_info {
      display: inline;
    }

    .copyright {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .address_area:last-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .corp_area {
    .corp_list {
      position: relative;
    }

    .corp_item {
      display: inline-block;
      font-weight: 700;
      font-size: 18px;
      color: ${STYLE_GUIDE.COLOR.black3};

      a {
        color: ${STYLE_GUIDE.COLOR.black3};
      }
    }

    .corp_item:not(:last-child) {
      margin-right: 50px;
    }

    .corp_item.dropdown_list {
      width: 180px;
      height: 50px;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      &.on:before {
        height: 180px;
        color: ${STYLE_GUIDE.COLOR.black4};
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.06);
      }
      > div {
        padding: 10px 20px;
        position: relative;
      }
    }

    .corp_item.dropdown_list:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      background: ${STYLE_GUIDE.COLOR.white};
      transition: all 0.3s ease;
      width: 180px;
      height: 50px;
      border: 1px solid #dbdbdb;
      border-radius: 30px;
    }

    .btn_service {
      cursor: pointer;
    }

    .dropdown_menu {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .info_area {
      width: auto;
      flex-direction: column;
    }

    .address_area {
      margin: 0;
      .sub_info {
        display: block;
      }
      .fax_number {
        display: block;
      }
    }

    .copy_wrap {
      padding: 10px 0 0 0;
    }

    .corp_area {
      padding: 50px 0 0 0;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    justify-content: space-between;
    padding-top: 30px;

    .logo_area {
      img {
        width: 158px;
      }
    }

    .info_area {
      width: auto;
      flex-direction: column;
    }

    .address_area {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      .sub_info {
        display: block;
      }
      .fax_number {
        display: block;
      }
      .tel_number {
        display: block;
      }
      .copr_num {
        display: block;
      }
      .copy_wrap {
        padding-top: 10px;
      }
      .copyright {
        color: ${STYLE_GUIDE.COLOR.gray1};
      }
    }

    .corp_area {
      padding: 20px 0 0 0;
      .corp_item {
        line-height: 26px;
        font-size: 16px;
      }

      .corp_item.dropdown_list {
        padding: 20px 0 0 0;
        width: 321px;
        display: block;
      }

      .corp_item.dropdown_list:before {
        width: 321px;
      }

      .btn_service {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  @media screen and (max-width: 883px) {
    display: block;
    padding: 41px 0 0 0;

    .logo_area {
      img {
        width: 160px;
      }
    }

    .info_area {
      display: block;
      padding-top: 20px;
    }

    .address_area {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      .sub_info {
        display: flex;
        flex-direction: column;
      }
      address > span {
        display: block;
      }

      .address_area > div {
        padding-top: 10px;
      }

      .copy_wrap {
        padding-top: 10px;
      }
      .copyright {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: ${STYLE_GUIDE.COLOR.gray1};
      }
    }

    .corp_area {
      padding: 20px 0 0 0;

      .corp_list .corp_item:not(:last-child) {
        margin-right: 20px;
      }

      .corp_list .corp_item:last-child {
        padding-top: 20px;
      }

      .dropdown_list {
        display: block;
      }

      .corp_item {
        font-size: 18px;
        line-height: 26px;
        a {
          color: ${STYLE_GUIDE.COLOR.black3};
        }
      }

      .corp_item.dropdown_list {
        cursor: pointer;
        display: block;
        width: 321px;

        .dropdown_menu {
          padding: 12px 20px;
        }
      }

      .corp_item.dropdown_list:before {
        width: 321px;
      }
    }
  }
`

export const DropdownMenu = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;

  &.on > ul {
    visibility: visible;
    animation: ${setOpacticy} 0.5s forwards;
    opacity: 1;
  }

  > ul {
    position: absolute;
    bottom: 50px;
    opacity: 0;
    visibility: hidden;
  }

  > ul > li {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: ${STYLE_GUIDE.COLOR.black4};
  }

  .btn_service {
    border-radius: 30px;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #636363;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 18px;
      cursor: pointer;
    }
  }

  &.on {
    .btn_service {
      img {
        transform: rotate(180deg);
      }
    }
  }

  @media screen and (max-width: 883px) {
    .btn_service {
      font-size: 16px;
      line-height: 26px;

      img {
        width: 26px;
        cursor: pointer;
      }
    }
  }
`
