import styled from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'
import ServiceInfoComponent from 'component/ServiceInfoComponent'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import TopButton from "../component/common/TopButton";

export const ServiceTitle = styled.div`
  padding: 220px 0 0 0;

  h2,
  p {
    font-weight: 700;
    color: ${STYLE_GUIDE.COLOR.black1};
  }

  h2 {
    font-size: 56px;
    line-height: 72px;
  }

  p {
    padding: 30px 0 0 0;
    font-size: 20px;
    line-height: 32px;
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    padding: 100px 0 0 0;
    h2 {
      font-size: 36px;
      line-height: 48px;
    }

    p {
      font-size: 16px;
      line-height: 26px;
      padding: 20px 0 0 0;
    }
  }

  @media screen and (max-width: 883px) {
    padding: 100px 0 0 0;

    h2 {
      font-size: 36px;
      line-height: 48px;
    }

    p {
      padding: 20px 0 0 0;
      font-size: 16px;
      line-height: 26px;
    }
  }
`

const Serivce = (props) => {
  const location = useLocation()
  const section = location.state?.section

  const isMobile = useMediaQuery({
    query: '(max-width:763px)',
  })

  return (
    <div className="container">
      <ServiceTitle>
        <h2>
          안전하고 편리한 삶을 위한
          {isMobile ? '' : <br />}
          데이터유니버스의 솔루션
        </h2>
        <p>
          데이터유니버스는 누구나 쉽게 사용할 수 있는 서비스를 제공하고자
          {isMobile ? ' ' : <br />}
          지속적인 노력과 새로운 도전을 계속합니다.
        </p>
      </ServiceTitle>
      <ServiceInfoComponent section={section}></ServiceInfoComponent>
      <TopButton/>
    </div>
  )
}
export default Serivce
