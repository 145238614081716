import { useEffect, useState } from 'react';
import pcBackground from 'assets/video/PC-background-8sec.mp4'
import mobileBackground from 'assets/video/mobile-background-8sec.mp4'

const VideoComponent = () => {
    const [videoSource, setVideoSource] = useState('');

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 1200) {
                setVideoSource(pcBackground);
            } else {
                setVideoSource(mobileBackground);
            }
        };

        handleResize(); // 초기 렌더링 시 실행

        window.addEventListener('resize', handleResize); // 화면 크기 변경 시 실행

        return () => {
            window.removeEventListener('resize', handleResize); // 컴포넌트 언마운트 시 이벤트 리스너 제거
        };
    }, []);

    return <video className="video" src={videoSource} autoPlay={true} loop muted playsInline="playsinline"/>;
};

export default VideoComponent;
