import { CustomModal, customStyles } from 'styles/common/modal'
import patentImg from 'assets/images/patent/tech_img.png'
import innobizImg from 'assets/images/patent/innobiz_img.png'
import { useEffect } from 'react'
import styled from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'

const ModalButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 26px;
  border: 2px solid #b1b1b1;
  border-radius: 120px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: ${STYLE_GUIDE.COLOR.white};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border: 2px solid ${STYLE_GUIDE.COLOR.gray4};
    background: ${STYLE_GUIDE.COLOR.gray4};
    color: ${STYLE_GUIDE.COLOR.black2};
  }
`

const PatentModal = (props) => {
  const { innobizFlag, patentsImg } = props

  useEffect(() => {}, [])

  return (
    <CustomModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      contentLabel="Patent Modal"
      ariaHideApp={false}
      style={customStyles}
    >
      {patentsImg && <img src={patentsImg} alt="" />}
      {!patentsImg && innobizFlag && <img src={innobizImg} alt="" />}
      {!patentsImg && !innobizFlag && <img src={patentImg} alt="" />}

      <ModalButton onClick={props.onRequestClose}>닫기</ModalButton>
    </CustomModal>
  )
}

export default PatentModal
