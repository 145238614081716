import STYLE_GUIDE from 'service/constant/color'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useEffect } from 'react'

import playstore from 'assets/icon/ico_playstore_16.png'
import appstore from 'assets/icon/ico_appstore_16.png'
import home from 'assets/icon/ico_hom_16.png'
import blog from 'assets/icon/ico_blog_16.png'

export const ServiceInfo = styled.div`
  .service_box:not(:last-child) {
    margin: 100px 0 130px 0;
  }
  .service_box:last-child{
    margin: 100px 0 60px;
  }
  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .service_box:last-child{
      margin: 100px 0 20px;
    }
  }
  @media screen and (max-width: 1199px){
    .service_box:last-child{
      margin: 100px 0 0;
    }
  }
  .service_box {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: ${(props) => (props.direction === 1 ? 'row-reverse' : '')};

    .shadow_box {
      position: absolute;
      width: 780px;
      height: 380px;
      border-radius: 40px;
      bottom: 0;

      ${(props) =>
        props.bgImg //bgImg 가 존재할 경우
          ? css`
              box-shadow: 0px 4px 30px #c6f9c9;
            `
          : css`
              box-shadow: 0px 4px 30px ${props.color};
            `}
    }

    .image_wrapper {
      position: relative;
      overflow: hidden;
      width: 780px;
      height: 530px;

      .image_box {
        position: absolute;
        bottom: 0;
        width: 780px;
        height: 380px;
        border-radius: 40px;

        ${(props) =>
          props.bgImg //bgImg 가 존재할 경우
            ? css`
                background-image: url(${props.bgImg});
              `
            : css`
                background-color: ${props.color};
                background-repeat: no-repeat;
                background-size: cover;
              `}
      }

      .move_image {
        position: absolute;
        top: 50px;
        left: 44px;
        width: 692px;
        height: 634px;
        transition: all 0.3s ease;
      }

      &:hover {
        .move_image {
          top: 0px;
        }
        .image_box {
          box-shadow: none;
          opacity: 0.5;
        }
      }
    }

    .content_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: end;
      width: 780px;

      .detail_content {
        padding: 10px 0 0 0;
      }

      .content_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 48px;
        color: ${STYLE_GUIDE.COLOR.black1};
      }

      .content_info,
      .content {
        font-weight: 400;
      }
      .content_info {
        padding: 6px 0 0 0;
        font-size: 18px;
        line-height: 30px;
        color: ${STYLE_GUIDE.COLOR.black4};
      }
      .content {
        padding: 23px 0 0;
        font-size: 22px;
        line-height: 34px;
        color: #000000;
      }
    }
    .label_wrapper {
      padding: 40px 0 0;
      .label_list {
        display: inline-block;
        padding: 0 20px 0 0;
      }
    }

    @media screen and (max-width: 1919px) and (min-width: 1200px) {
      display: block;

      .shadow_box {
        position: absolute;
        width: 100%;
        height: 380px;
        border-radius: 40px;

        ${(props) =>
          props.bgImg //bgImg 가 존재할 경우
            ? css`
                box-shadow: 0px 4px 30px #c6f9c9;
              `
            : css`
                box-shadow: 0px 4px 30px ${props.color};
              `}

        &.Anti-Scam {
          bottom: 459px;
        }

        &.MFinder {
          bottom: 425px;
        }

        &.AutoCall {
          bottom: 425px;
        }

        &.FamilyCare {
          bottom: 426px;
        }

        &.EVENT·U {
          bottom: 351px;
        }
      }

      .image_wrapper {
        width: 100%;

        .image_box {
          width: 100%;
        }

        .move_image {
          width: 887px;
          height: 812px;
          left: 56px;
        }
      }

      .content_wrapper {
        width: 100%;
        padding: 50px 0 0 0;
      }

      .label_wrapper {
        padding: 40px 0 0;
        .label_list {
          display: inline-block;
          padding: 0 20px 0 0;
        }
      }
    }

    @media screen and (max-width: 1199px) and (min-width: 884px) {
      display: block;
      margin: 34px 0 80px 0;

      .shadow_box {
        box-shadow: unset;
      }

      .image_wrapper {
        width: 100%;

        .image_box {
          width: 100%;
        }

        .move_image {
          width: 680px;
          height: 520px;
          left: 42px;
          top: 0;
        }

        &:hover {
          .image_box {
            opacity: 1;
          }
        }
      }

      .content_wrapper {
        width: 100%;
        padding: 30px 0 0 0;
        .service_icon {
          width: 50px;
        }

        .detail_content {
          padding: 0;

          .content_title {
            font-size: 24px;
            line-height: 36px;
          }

          .content {
            padding: 20px 0 0;
            font-size: 18px;
            line-height: 30px;
          }

          .content_info {
            font-size: 16px;
            line-height: 30px;
            padding: 2px 0 0 0;
          }
        }
      }
      .label_wrapper.EVENT·U{
        padding: 0;
      }
    }

    @media screen and (max-width: 883px) {
      display: block;
      margin: 32px 0 60px 0;

      .image_wrapper {
        width: 320px;
        height: 218px;
        .image_box {
          width: 320px;
          height: 155px;
        }

        .move_image {
          width: 284px;
          height: 218px;
          top: 0;
          left: 17px;
        }

        &:hover {
          .image_box {
            opacity: 1;
          }
        }
      }

      .content_wrapper {
        width: 100%;
        padding: 28px 0 0 0;

        .service_icon {
          width: 50px;
        }

        .detail_content {
          .content_title {
            font-size: 24px;
            line-height: 36px;
          }

          .content_info {
            padding: 2px 0px 0px;
            font-size: 16px;
            line-height: 30px;
          }

          .content {
            padding: 20px 0px 0px;
            font-size: 18px;
            line-height: 30px;
            width: 320px;
          }
        }
      }

      .label_wrapper {
        padding: 30px 0px 0px;
        width: 320px;

        .label_list {
          display: inline-block;
        }

        .label_list:nth-child(3) {
          padding: 20px 20px 0 0;
        }
      }
      .label_wrapper.EVENT·U{
        padding: 0;
      }
    }
  }
`

export const LabelButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px 8px;
  // width: 141px;
  height: 40px;
  background: ${STYLE_GUIDE.COLOR.gray3};
  border-radius: 120px;

  // 디자인에 없는데 해도 되는지..?
  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: ${STYLE_GUIDE.COLOR.black2};
    padding: 0 0 0 6px;
  }
`

const ServiceInfoComponent = (props) => {
  const section = props?.section

  const isMobile = useMediaQuery({
    query: '(max-width:883px)',
  })

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top:
          document.querySelector('#section' + section)?.offsetTop -
          document.querySelector('.header')?.offsetHeight,
        behavior: 'smooth',
      })
      window.history.replaceState({}, document.title)
    }, 300)
  }, [section])

  const serviceList = [
    {
      code: 1,
      title: '스마트피싱보호',
      enTitle: 'Anti-Scam',
      info: '국내 최초 금융사기 예방 서비스',
      content: `스마트피싱보호는 빅데이터 분석으로 예측된 최신/주요 피싱정보를 주기적으로 제공하여 변화하는 피싱사기를 미리 알고 예방할 수 있도록 돕고 있습니다.<br/>피싱 의심전화와 장시간 통화 시 제공되는 단계별 알림 등 다양한 방법으로 나와 소중한 가족이 피싱사기 피해로부터 보호받을 수 있습니다.`,
      color: `${STYLE_GUIDE.SERVICE.antiScam}`,
      img: require('assets/images/service/img_anti-scam_692.png'),
      icon: require('assets/icon/service/img_sfb_76.png'),
      direction: 0,
      link: [
        {
          flag: true,
          type: 'Play store',
          link: 'https://datauniverse.page.link/DU6r',
          icon: `${playstore}`,
        },
        {
          flag: true,
          type: 'App store',
          link: 'https://apps.apple.com/kr/app/%EC%8A%A4%EB%A7%88%ED%8A%B8%ED%94%BC%EC%8B%B1%EB%B3%B4%ED%98%B8-%EB%B3%B4%EC%9D%B4%EC%8A%A4%ED%94%BC%EC%8B%B1-%EC%9D%98%EC%8B%AC%EB%B2%88%ED%98%B8-%EC%88%98%EC%8B%A0-%EC%8B%9C-%EB%B0%9C%EC%8B%A0%EC%9E%90-%EB%B2%88%ED%98%B8/id1452978615',
          icon: `${appstore}`,
        },
        { flag: true, type: 'Home', link: 'https://www.antiscam.co.kr/', icon: `${home}` },
        { flag: true, type: 'Blog', link: 'https://blog.naver.com/datau2', icon: `${blog}` },
      ],
    },
    {
      code: 2,
      title: '휴대폰분실보호',
      enTitle: 'MFinder',
      info: '분실폰을 찾기 위한 다양한 기능',
      content:
        '휴대폰분실보호(MFinder)는 휴대폰을 잃어버린 경험이 있는 분들이라면 한 번쯤은 생각해 봤을 다양한 기능들을 제공합니다. 카메라 실행, 유심변경 감지, 위치조회, WIFI감지, 사이렌울리기 등 분실폰 회수 가능성을 높이기 위한 기능과 함께 데이터 백업, 복원, 삭제와 같은 정보 보호 기능을 제공합니다.',
      color: `${STYLE_GUIDE.SERVICE.mFinder}`,
      img: require('assets/images/service/img_mfinder_692.png'),
      icon: require('assets/icon/service/img_find_76.png'),
      bgImg: require('assets/images/service/img_mfinder-bg.png'),
      direction: 1,
      link: [
        {
          flag: true,
          type: 'Play store',
          link: 'https://mfinder.page.link/oGfi',
          icon: `${playstore}`,
        },
        {
          flag: false,
          type: 'App store',
          link: '',
          icon: `${appstore}`,
        },
        { flag: true, type: 'Home', link: 'https://www.mfinder.co.kr/home', icon: `${home}` },
        { flag: true, type: 'Blog', link: 'https://blog.naver.com/datau1', icon: `${blog}` },
      ],
    },
    {
      code: 3,
      title: '오토콜',
      enTitle: 'AutoCall',
      info: '운전자 개인정보 보호 서비스',
      content:
        '오토콜은 운전자와 호출자의 개인정보 노출 없이 차량번호만으로 운전자를 호출합니다. 차량에 부착한 휴대폰번호 유출로 인한 피해를 예방하고, 호출자와 운전자 간 유쾌하지 못한 경험을 방지할 수 있습니다. 아파트 도입을 통해 맞춤 디자인된 무점착 고급 주차스티커로 입주민 차량관리를 편리하게 할 수도 있습니다.',
      color: `${STYLE_GUIDE.SERVICE.subAutocall}`,
      img: require('assets/images/service/img_autocall_692.png'),
      icon: require('assets/icon/service/img_auto_76.png'),
      direction: 0,
      link: [
        {
          flag: true,
          type: 'Play store',
          link: 'https://autocall.page.link/H3Ed ',
          icon: `${playstore}`,
        },
        {
          flag: false,
          type: 'App store',
          link: '',
          icon: `${appstore}`,
        },
        { flag: true, type: 'Home', link: 'https://www.antiscam.co.kr/', icon: `${home}` },
        { flag: true, type: 'Blog', link: 'https://blog.naver.com/datau', icon: `${blog}` },
      ],
    },
    {
      code: 4,
      title: '휴대폰가족보호',
      enTitle: 'FamilyCare',
      info: '현실을 넘어 가족을 보호하다',
      content:
        '항상 소지하고 있는 휴대폰 데이터를 기반으 로 구성원을 연결하고 소통을 도와 서로의 안위를 확인합니다. 가족 구성원의 활동량, 위치 및 날씨 공유, 안심존 이탈 확인을 통해 서로를 보호하고 소통할 수 있습니다. 낙상으로 인한 충격 및 미사용 알림 기능을 통해 위급상황의 골든타임을 놓치지 않을 수 있습니다.',
      color: `${STYLE_GUIDE.SERVICE.subFamily}`,
      img: require('assets/images/service/img_family.png'),
      icon: require('assets/icon/service/img_care_76.png'),
      direction: 1,
      link: [
        {
          flag: true,
          type: 'Play store',
          link: 'https://teslag.page.link/iGuj',
          icon: `${playstore}`,
        },
        {
          flag: false,
          type: 'App store',
          link: '',
          icon: `${appstore}`,
        },
        { flag: true, type: 'Home', link: 'https://www.familycare.ai/', icon: `${home}` },
        { flag: true, type: 'Blog', link: 'https://blog.naver.com/datau3', icon: `${blog}` },
      ],
    },
    {
      code: 5,
      title: '이벤트U',
      enTitle: 'EVENT·U',
      info: '세상의 모든 이벤트',
      content:
        '이벤트U는 MD가 직접 선별한 이벤트, 특가 상품을 제공하는 이벤트 마케팅 플랫폼입니다. 고객은 이벤트에 응모하거나 할인 된 가격으로 상품을 구매할 수 있고, 플랫폼사와 광고주는 제품을 홍보하며 함께 성장할 수 있습니다.',
      color: `${STYLE_GUIDE.SERVICE.subEventu}`,
      img: require('assets/images/service/img_event-u_692.png'),
      icon: require('assets/icon/service/img_event_76.png'),
      direction: 0,
      link: [
        {
          flag: false,
          type: 'Play store',
          link: 'http://s.antiscam.co.kr/',
          icon: `${playstore}`,
        },
        {
          flag: false,
          type: 'App store',
          link: '',
          icon: `$`,
        },
        { flag: false, type: 'Home', link: 'https://www.antiscam.co.kr/', icon: `${home}` },
        { flag: false, type: 'Blog', link: '', icon: `${blog}` },
      ],
    },
  ]

  return (
    <>
      {serviceList.map((item) => (
        <ServiceInfo
          key={item.code}
          color={item.color}
          direction={item.direction}
          bgImg={item.bgImg}
          id={`section` + item.code}
        >
          <div className="service_box">
            {!isMobile && <div className={`shadow_box ${item.enTitle}`}></div>}
            <div className="image_wrapper">
              <div className="image_box"></div>
              <figure className="move_image">
                <img src={item.img} alt="" />
              </figure>
            </div>

            <div className="content_wrapper">
              <figure>
                <img className="service_icon" src={item.icon} alt="" />
              </figure>
              <div className="detail_content">
                <h3 className="content_title">
                  {item.title}({item.enTitle})
                </h3>
                <p className="content_info">{item.info}</p>
                <p className="content" dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
              <div className={`label_wrapper ${item.enTitle}`}>
                <ul>
                  {item.link.map(
                    (d, idx) =>
                      d.flag && (
                        <li className="label_list" key={idx}>
                          <a href={d.link} target="_blank">
                            <LabelButton>
                              <img src={d.icon} alt="" />
                              <span>{d.type}</span>
                            </LabelButton>
                          </a>
                        </li>
                      ),
                  )}
                </ul>
              </div>
            </div>
          </div>
        </ServiceInfo>
      ))}
    </>
  )
}

export default ServiceInfoComponent
