import { axiosDefaultData } from './utils'

// 최신 뉴스 리스트 호출
export const getRecentNewsList = async () => await axiosDefaultData('/news/recent')

// 뉴스 리스트 호출
export const getNewsList = async (pageNum) => await axiosDefaultData(`/news/pages/${pageNum}`)

// 뉴스 total 호출
export const getNewsTotalNum = async () => await axiosDefaultData(`/news/totalData`)

// 특허증 리스트 호출
export const getPatentList = async (pageNum) => await axiosDefaultData(`/patents/pages/${pageNum}`)

// 특허 total 호출
export const getPatentTotalNum = async () => await axiosDefaultData(`/patents/totalData`)
