import styled, { keyframes } from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'
import duMadeImg from 'assets/images/main/img_DU-Made_1920.png'
import recruitImg from 'assets/images/main/img_home-recruit_1600.png'

const setBoundFrames = keyframes` 
from {transform: translate3d(0, 0, 0);}
to {transform: translate3d(0, -15px, 0);}
`

export const MainWrap = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  .swiper {
    z-index: 1;
    height: 100vh;
    .swiper-wrapper {
      .swiper-slide {
        margin-bottom: 0 !important;
      }
    }
  }

  .test_lottie {
    width: 900px;
    height: 100%;
  }

  .main_service_bg {
    height: 100vh;
    width: 100vw;
    z-index: 0;
    position: absolute;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .main_banner_section {
    // height: 1050px;
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    svg {
      // height: auto !important;
      position: absolute;
    }
  }
  .du_made_section {
    //height: 680px;
    height: 100vh;
  }
  .news_section {
    height: 518px;
    background: ${STYLE_GUIDE.COLOR.black2};
    color: ${STYLE_GUIDE.COLOR.white};
  }

  .service_section {
    height: 100vh;
  }

  .pop_icon {
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    animation: ${setBoundFrames} 0.6s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.2, 0.5, 0.5, 0.5);
    animation-iteration-count: infinite;
  }

  .swiper-slide > div > svg {
    height: auto !important;
    width: auto !important;
  }
  .swiper-slide {
    //-webkit-backface-visibility: hidden;
    //-webkit-transform: translate3d(0, 0, 0);
  }
  .lottie_bg {
    opacity: 80%;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
  }
  .service_container {
    width: 500%;
    height: 100vh;
    display: flex;
    flex-wrap: noWrap;
  }
  .service {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    .anti_section,
    .mfinder_section,
    .familycare_section,
    .eventu_section,
    .autocall_section {
      width: 100%;
      height: 100%;
      .image_contents {
        width: 100%;
        height: 100%;
        top: 50% !important;
        transform: translate(0px, -50%) !important;
        position: absolute;
        opacity: 1;
      }
      .image_wrapper {
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
    .left_section {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translate(0, -50%);
      .service_title {
        font-weight: 700;
        font-size: 56px;
        line-height: 72px;
        color: #ffffff;
      }
      .icon_area {
        padding: 50px 0 120px;
        display: flex;
        text-align: center;
        align-items: center;
        .service_name {
          padding: 0 0 0 20px;
          font-weight: 400;
          font-size: 36px;
          line-height: 48px;
          color: #ffffff;
        }
      }
    }
  }
  .service1 {
    .pin {
      width: 4%;
      right: 50%;
      position: absolute;
      z-index: 2;
    }
    .image1 {
      position: absolute;
      z-index: 1;
      top: 50%;
      width: 50%;
      right: 5%;
      transform: translate(0px, -50%);
    }
    .image2 {
      position: absolute;
      right: 51%;
      top: 70%;
      transform: translate(0px, -50%);
    }
  }
  .service2 {
    .image1 {
      right: 120px;
      position: absolute;
      z-index: 1;
      top: 50%;
      width: 40%;
      transform: translate(0px, -40%);
    }
    .pin2 {
      top: 55%;
      z-index: 2;
      width: 18%;
      right: 38%;
      position: absolute;
    }
  }
  .service3 {
    top: 50%;
    transform: translate(0px, -50%);
    .car {
      right: -5%;
      position: absolute;
      /* bottom: 0px; */
      z-index: 2;
      top: 50%;
      transform: translate(0px, 50%);
    }
    .smile {
      transform: translate(0px, 60%);
      width: 7.5%;
      right: 30%;
      /* right: 24%; */
      position: absolute;
      /* top: 83px; */
      z-index: 2;
    }
    .image1 {
      position: absolute;
      /* top: 30px; */
      z-index: 1;
      top: 50%;
      width: 50%;
      transform: translate(70%, -50%);
    }
  }
  .service4 {
    .heart {
      width: 19%;
      right: 46%;
      position: absolute;
      bottom: 0;
      z-index: 2;
      top: 72%;
      transform: translate(10%, -50%);
    }
    .image1 {
      width: 68%;
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translate(50%, -50%);
    }
  }
  .service5 {
    .box1 {
      right: 46%;
      width: 19%;
      bottom: 0;
      position: absolute;
      top: 65%;
      transform: translate(0px, -50%);
      z-index: 2;
    }
    .box2 {
      right: 0%;
      width: 30%;
      bottom: 0;
      position: absolute;
      top: 60%;
      transform: translate(0px, -50%);
      z-index: 2;
    }
    .image1 {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 50%;
      transform: translate(83%, -50%);
      width: 43%;
    }
  }
  .lottie_color1 {
    background-color: #006d3f;
  }
  .lottie_color2 {
    background-color: #67c51d;
  }
  .lottie_color3 {
    background-color: #00479a;
  }
  .lottie_color4 {
    background-color: #e3112a;
  }
  .lottie_color5 {
    background-color: #6111e3;
  }
  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    //.main_service_bg {
    //  height: calc(var(--vh, 1vh) * 100);
    //}
    .test_lottie {
      width: 720px;
    }

    .service1 {
      .pin {
        width: 3.5%;
      }
      .image2 {
        top: 62%;
        width: 13%;
      }
    }
    .service2 {
      .image1 {
        width: 40%;
        right: 30%;
        transform: translate(50%, -50%);
      }
      .pin2 {
        right: 40%;
      }
    }
    .service3 {
      .smile {
        right: 20%;
        top: 30%;
        transform: translate(0px, -50%);
      }
      .car {
        width: 30%;
        top: 52%;
      }
      .image1 {
        width: 50%;
        transform: translate(90%, -50%);
      }
    }
    .service4 {
      .heart {
        top: 67%;
      }
      .image1 {
        width: 65%;
      }
    }
    .service5 {
      .image1 {
        width: 45%;
      }
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    //.main_service_bg {
    //  height: calc(var(--vh, 1vh) * 100);
    //}
    .test_lottie {
      width: 658px;
    }
    .service {
      .shadow {
        height: 300px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 38.57%,
          rgba(0, 0, 0, 0.7) 100%
        );
      }
      .left_section {
        bottom: 70px;
        top: auto;
        .service_title {
          font-size: 28px;
          line-height: 40px;
        }
        .bottom_area {
          padding: 30px 0 0;
          display: flex;
        }
        .du_button {
          padding: 0;
          width: 100px;
          height: 38px;
          font-size: 14px;
        }
        .icon_area {
          padding: 0;
          img {
            width: 40px;
          }
          .service_name {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
      .anti_section .du_button,
      .mfinder_section .du_button,
      .familycare_section .du_button {
        margin: 0 0 0 54px;
      }
      .eventu_section .du_button {
        margin: 0 0 0 107px;
      }
      .autocall_section .du_button {
        margin: 0 0 0 120px;
      }
    }

    .service1 {
      .image1 {
        position: absolute;
        z-index: 1;
        top: 45%;
        width: 90%;
        right: 0%;
        transform: translate(0px, -50%);
      }

      .image2 {
        position: absolute;
        right: 60%;
        width: 20%;
        top: 30%;
        transform: translate(0px, -50%);
      }
    }

    .service2 {
      .image1 {
        top: 45%;
        width: 70%;
        transform: translate(0px, -50%);
      }
      .pin2 {
        right: 55%;
        width: 25%;
        //top: 16%;
        transform: translate(0px, -50%);
      }
    }
    .service3 {
      .smile {
        width: 15.5%;
      }
      .image1 {
        top: 40%;
        width: 90%;
        right: 50%;
        transform: translate(50%, -50%);
      }
      .car {
        width: 50%;
        top: 50%;
      }
    }
    .service4 {
      .heart {
        right: 60%;
        top: 60%;
        transform: translate(0px, -50%);
      }
      .image1 {
        top: 40%;
        width: 100%;
        right: 50%;
        transform: translate(50%, -50%);
      }
    }

    .service5 {
      .image1 {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
      }
      .box1 {
        right: 62%;
      }
      .box2 {
        right: 30%;
        transform: translate(50%, -50%);
      }
    }
  }

  @media screen and (max-width: 883px) {
    //.main_service_bg {
    //  height: calc(var(--vh, 1vh) * 100);
    //}
    .swiper-wrapper {
      height: 100dvh;
    }
    .test_lottie {
      width: 360px;
      top: 40%;
      transform: translate(0px, -50%);
    }
    //.du_made_section {
    //  height: 100svh;
    //}
    //.main_banner_section {
    //  height: 100svh;
    //}

    .service1 {
      .pin {
        width: 8%;
      }
      .image1 {
        position: absolute;
        z-index: 1;
        top: 40%;
        width: 100%;
        right: 0%;
        transform: translate(0px, -50%);
      }

      .image2 {
        position: absolute;
        right: 60%;
        width: 20%;
        top: 30%;
        transform: translate(0px, -50%);
      }
    }
    .service2 {
      .image1 {
        top: 40%;
        width: 90%;
        right: 50%;
        transform: translate(50%, -50%);
      }
      .pin2 {
        right: 55%;
        width: 30%;
        //top: 20%;
        transform: translate(0px, -50%);
      }
    }
    .service3 {
      .image1 {
        top: 40%;
        width: 100%;
        transform: translate(0px, -50%);
      }
      .car {
        width: 50%;
        top: 50%;
      }
      .smile {
        transform: translate(0px, 50%);
        width: 12.5%;
        right: 30%;
        top: 19%;
      }
    }
    .service4 {
      .image1 {
        top: 40%;
        width: 100%;
        right: 50%;
        transform: translate(50%, -50%);
      }
      .image2 {
        right: 0;
      }
      .heart {
        width: 26%;
        right: 63%;
        top: 53%;
      }
    }
    .service5 {
      .box1 {
        width: 25%;
        top: 55%;
        right: 70%;
      }
      .box2 {
        width: 40%;
        top: 50%;
      }
      .image1 {
        transform: translate(50%, -50%);
        right: 50%;
        top: 40%;
        width: 100%;
      }
    }
    .service {
      .shadow {
        height: 300px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 38.57%,
          rgba(0, 0, 0, 0.7) 100%
        );
      }
      .left_section {
        width: 100%;
        bottom: 30px;
        top: auto;
        .bottom_area {
          padding: 30px 0 0;
        }
        .du_button {
          padding: 0;
          width: 100px;
          height: 38px;
          font-size: 14px;
        }
        .service_title {
          font-size: 28px;
          line-height: 40px;
        }
        .bottom_area {
          display: flex;
          justify-content: space-between;
        }
        .icon_area {
          padding: 0;
          img {
            width: 40px;
          }

          .service_name {
            padding: 0 0 0 10px;
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    //.swiper,.main_banner_section,.du_made_section {
    //  height: calc(var(--vh, 1vh) * 100);
    //}
    .news_section {
      height: 540px;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    //.swiper,.main_banner_section,.du_made_section {
    //  height: calc(var(--vh, 1vh) * 100);
    //}
    .du_made_section {
      .du_button {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .news_section {
      height: 446px;

      .news_button {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .service_section {
      .title {
        font-size: 46px;
        line-height: 52px;
      }
    }
  }

  @media screen and (max-width: 883px) {
    .swiper {
      //height: calc(var(--vh, 1vh) * 100);
    }
    .main_banner_section {
      // height: 620px;
      //height: calc(var(--vh, 1vh) * 100);
      //min-height: -webkit-fill-available;
      // height: -webkit-fill-available;
      // height: fill-available;
    }
    .pop_icon {
      bottom: 120px;
    }
    .du_made_section {
      //height: calc(var(--vh, 1vh) * 100);
      //height: 604px;
    }
    .news_section {
      height: 694px;
    }
  }
`

export const deaultSection = styled.div`
  h2 {
    font-weight: 800;
    line-height: 70px;
    font-size: 60px;
  }
`

export const SubSection1 = styled(deaultSection)`
  background-image: url(${duMadeImg});
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center center;
  // background-attachment: fixed;
  width: 100%;
  color: ${STYLE_GUIDE.COLOR.white};

  h2 {
    flex: 0.5;
  }
  .content_wrap {
    //display: flex;
    //justify-content: space-between;
    width: 100%;
    //padding-top: 332px;
    top: 50% !important;
    position: absolute;
    transform: translate(0, -50%) !important;
    .caption {
      opacity: 0;
    }
    p {
      font-size: 28px;
      text-align: left;
      font-weight: 400;
      color: ${STYLE_GUIDE.COLOR.gray3};
      padding: 40px 0 0;
    }
    button {
      margin: 140px 0 0;
      font-weight: 700;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    button {
      margin-top: 50px;
      font-weight: 700;
      font-size: 18px;
    }
    .content_wrap {
      padding-top: 130px;
      text-align: left;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    h2 {
      font-size: 46px;
      line-height: 52px;
    }
    p {
      padding-top: 30px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 400;
      width: 764px;
      color: ${STYLE_GUIDE.COLOR.gray3};
    }
    .content_wrap {
      //padding-top: 398px;
      display: block;
      text-align: left;
      p {
        font-size: 20px;
        line-height: 32px;
        padding: 50px 0 0;
      }
      button {
        margin-top: 60px;
        padding: 11px 20px 13px;
      }
    }
  }

  @media screen and (max-width: 883px) {
    display: block;
    text-align: left;

    h2 {
      font-size: 46px;
      line-height: 52px;
    }
    .content_wrap {
      //padding-top: 198px;
      display: block;
      text-align: left;
      p {
        font-size: 20px;
        line-height: 32px;
        padding: 50px 0 60px 0;
      }
      button {
        margin: 0;
        font-size: 16px;
        line-height: 26px;
        padding: 11px 20px 13px;
      }
    }
  }
`

export const SubSection2 = styled(deaultSection)`
  background-color: ${STYLE_GUIDE.COLOR.gray4};
  text-align: left;
  .content_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 100px;
    // > div {
    //   width: 1061px;
    // }

    h2 {
      flex: 1;
    }

    .news_wrap {
      flex: 1.9;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
  }

  p {
    height: 96px;
    margin: 20px 0 50px;
    display: -webkit-box;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: ${STYLE_GUIDE.COLOR.gray1};
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    // .content_wrap {
    //   > div {
    //     width: 657px;
    //   }
    // }

    p {
      height: 128px;
      -webkit-line-clamp: 4;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    .content_wrap {
      padding-top: 80px;
      display: block;
      // width: 764px;

      > div {
        padding-top: 30px;
      }
    }

    h2 {
      font-weight: 800;
      font-size: 46px;
      line-height: 52px;
    }

    h3 {
      font-weight: 700;
      font-size: 22px;
      line-height: 34px;
    }

    p {
      margin: 20px 0px 40px;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      height: 60px;
      -webkit-line-clamp: 2;
    }

    button {
      padding: 11px 20px 13px;
    }
  }

  @media screen and (max-width: 883px) {
    .content_wrap {
      display: block;

      > div {
        padding: 70px 0 0 0;
      }
    }

    h2 {
      font-size: 46px;
      line-height: 52px;
    }

    h3 {
      font-size: 22px;
      line-height: 34px;
      width: 320px;
    }

    .news_content {
      height: 120px;
      margin: 20px 0px 40px;
      -webkit-line-clamp: 4;
      line-height: 30px;
    }

    .button {
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      padding: 11px 20px 13px;
    }
  }
`

export const SubSection3 = styled(deaultSection)`
  position: relative;
  overflow: hidden;

  .service_main > div > svg {
    height: 100% !important;
    width: 100% !important;
  }
  .lottie_bg {
    opacity: 80%;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
  }
  .service_container {
    width: 500%;
    height: 100vh;
    display: flex;
    flex-wrap: noWrap;
  }
  .service {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    .anti_section,
    .mfinder_section {
      width: 100%;
      height: 100%;
      .image_wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        top: 50%;
        transform: translate(0px, -50%);
      }
    }

    .left_section {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translate(0, -50%);
      .service_title {
        font-weight: 700;
        font-size: 56px;
        line-height: 72px;
        color: #ffffff;
      }
      .icon_area {
        display: flex;
        text-align: center;
        align-items: center;
        padding: 50px 0 120px;
        .service_name {
          padding: 0 0 0 20px;
          font-weight: 400;
          font-size: 36px;
          line-height: 48px;
          color: #ffffff;
        }
      }
    }
  }
  .service1 {
    .pin {
      width: 4%;
      right: 50%;
      position: absolute;
    }
    .image1 {
      position: absolute;
      z-index: 1;
      top: 50%;
      width: 50%;
      right: 5%;
      transform: translate(0px, -50%);
    }
    .image2 {
      position: absolute;
      right: 51%;
      top: 70%;
      transform: translate(0px, -50%);
    }
  }
  .service2 {
    .image1 {
      right: 120px;
      position: absolute;
      z-index: 1;
      top: 50%;
      width: 40%;
      transform: translate(0px, -40%);
    }
    .pin2 {
      top: 50%;
      z-index: 2;
      width: 21%;
      right: 46%;
      position: absolute;
    }
  }
  .service3 {
    .car {
      width: 30%;
      right: -5%;
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
    .smile {
      width: 11.5%;
      right: 24%;
      position: absolute;
      top: 83px;
      z-index: 2;
    }
    .image1 {
      right: 124px;
      position: absolute;
      top: 30px;
      z-index: 1;
    }
  }
  .service4 {
    .heart {
      width: 19%;
      right: 46%;
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
    .image1 {
      right: 8%;
      position: absolute;
      z-index: 1;
    }
    .image2 {
      right: -18%;
      position: absolute;
      top: 0;
    }
  }
  .service5 {
    .image1 {
      right: 184px;
      position: absolute;
      z-index: 1;
      top: 69px;
    }
  }
  .lottie_color1 {
    background-color: #006d3f;
  }
  .lottie_color2 {
    background-color: #67c51d;
  }
  .lottie_color3 {
    background-color: #00479a;
  }
  .lottie_color4 {
    background-color: #e3112a;
  }
  .lottie_color5 {
    background-color: #6111e3;
  }
  .mySwiper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .service1 {
      .pin {
        width: 3.5%;
      }
      .image1 {
        width: 62%;
      }
      .image2 {
        width: 16%;
        right: 46%;
      }
    }
    .service2 {
      .image1 {
        width: 33%;
        right: 50px;
      }
    }
    .service3 {
      .smile {
        width: 9%;
      }
      .image1 {
        width: 61%;
        right: 0%;
      }
    }
    .service4 {
      .image1 {
        width: 48%;
      }
      .image2 {
        width: 96%;
      }
    }
    .service5 {
      .image1 {
        width: 53%;
        right: 8%;
      }
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    .service {
      .left_section {
        bottom: 70px;
        top: auto;

        .service_title {
          font-size: 28px;
          line-height: 40px;
        }

        .bottom_area {
          display: flex;
        }

        .icon_area {
          padding: 30px 0 0;

          img {
            width: 40px;
          }

          .service_name {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 883px) {
    .service {
      .left_section {
        bottom: 70px;
        top: auto;

        .service_title {
          font-size: 28px;
          line-height: 40px;
        }

        .bottom_area {
          display: flex;
        }

        .icon_area {
          padding: 30px 0 0;

          img {
            width: 40px;
          }

          .service_name {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }
`

export const SubSection4 = styled(deaultSection)`
  padding: 130px 0 0;
  // padding: 550px 0 0 0;

  .story_box {
    display: flex;
    padding: 40px 0 0 0;
    justify-content: space-between;

    &:hover {
      .story_item.active {
        .img_box img {
          transform: scale(1.15);
        }
        .story_content {
          img {
            transform: translate(8px, -8px);
          }
        }
      }
    }
  }

  .story_item {
    .box {
      width: 780px;
      height: 380px;
      overflow: hidden;
      border-radius: 40px;
    }
    .img_box > img {
      cursor: pointer;
      display: block;
      transition: all 0.2s ease;
    }
  }

  .story_content {
    padding: 30px 0 0 0;
    display: flex;
    align-items: center;
    position: relative;

    h3 {
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: ${STYLE_GUIDE.COLOR.black1};
    }

    img {
      padding: 0 0 0 9px;
      width: 17px;
      // top: 40px;
      // left: 130px;
      // position: absolute;
      transition: all 0.2s ease;
    }
  }

  p {
    padding: 6px 0 0 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: ${STYLE_GUIDE.COLOR.gray1};
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .story_box {
      padding: 30px 0 0 0;

      &:hover {
        .story_item.active {
          .story_content {
            img {
              top: 20px;
              left: 140px;
            }
          }
        }
      }
    }

    .story_item {
      .box {
        width: 485px;
        height: 236px;
      }
    }

    .story_content {
      padding: 20px 0 0 0;

      img {
        top: 30px;
      }
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    // padding: 357px 0px 0px;
    padding: 100px 0px 0px;

    .title {
      font-size: 46px;
      line-height: 52px;
    }

    .story_box {
      padding: 30px 0 0 0;
    }

    .story_item {
      .box {
        width: 367px;
        height: 218px;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .story_content {
      padding: 10px 0 0 0;

      h3 {
        font-size: 24px;
        line-height: 36px;
      }
      img {
        left: 95pxpx;
        top: 20px;
      }
    }
  }

  @media screen and (max-width: 883px) {
    padding: 100px 0 0 0;
    .title {
      font-size: 46px;
      line-height: 52px;
    }
    .story_box {
      display: flex;
      flex-direction: column;
      padding: 30px 0 0 0;
    }

    .story_item {
      .box {
        width: 320px;
        height: 190px;
        border-radius: 30px;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding: 0;
      }

      &:first-child {
        padding: 0 0 30px 0;
      }
    }

    .story_content {
      padding: 10px 0px 0px;

      h3 {
        font-size: 24px;
        line-height: 36px;
      }

      img {
        padding: 0 0 0 10px;
        // width: 17px;
        // padding: 0;
        // top: 20px;
      }
    }
  }
`
export const SubSection5 = styled(deaultSection)`
  padding: 130px 0 0;
  isolation: isolate;

  // background: url(${recruitImg}) no-repeat 50% 100% / cover;
  // background-attachment: fixed;
  .recruit {
    isolation: isolate;
    display: block;
    padding: 152px 0 152px 137px;
    border-radius: 40px;
    color: ${STYLE_GUIDE.COLOR.white};
    .recruit_content {
      padding: 20px 0 50px;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
    }
    .recruit_title {
      font-weight: 700;
      font-size: 46px;
      line-height: 62px;
    }
  }
  .react-parallax-bgimage {
    top: -200px;
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .recruit {
      padding: 122px 90px;
    }

    .react-parallax-bgimage {
      top: -100px;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    padding: 100px 0 0;
    .recruit {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 66px 255px;

      .recruit_title {
        font-size: 28px;
        line-height: 40px;
      }
      .recruit_content {
        font-size: 14px;
        line-height: 24px;
        padding: 10px 0 40px 0;
      }
      .recruit_btn {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
    .react-parallax-bgimage {
      top: 0;
    }
  }

  @media screen and (max-width: 883px) {
    padding: 100px 0 0;
    text-align: center;

    .recruit {
      padding: 116px 30px;
      text-align: center;
      background-size: 250%;
      .recruit_content {
        font-size: 14px;
        line-height: 24px;
        padding: 10px 0 40px 0;
      }
      .recruit_title {
        font-size: 28px;
        line-height: 40px;
      }
      .recruit_btn {
        display: flex;
        justify-content: center;
      }
    }
    .react-parallax-bgimage {
      top: 0;
    }
  }
`
